import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { customAlert } from "../../utils/alert";
import { HTTPURL } from "../../constant/Matcher";
import { redirect } from "react-router-dom";

// add member
export const Add_Member = createAsyncThunk(
    "/api/add-member",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/add-member`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                customAlert(res?.data?.message, 'success');
            } else {
                customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);
//get reces
export const get_races = createAsyncThunk(
    "/api/races",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/races`,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            // if (res?.data?.status == true) {
            //     customAlert(res?.data?.message, 'success');
            // } else {
            //     customAlert(res?.data?.message);
            // }
            return res.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);

//get  All Members
export const get_all_members = createAsyncThunk(
    "auth/list-member",
    async (SearchData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/list-member?page=${SearchData?.page}&homeGroup=${SearchData?.home}&searchQuery=${SearchData?.searchBar}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);

// edit member
export const edit_member = createAsyncThunk(
    "auth/edit-member",
    async (id, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/edit-member?id=${id}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);

//Delete single member
export const Delete_single_member = createAsyncThunk(
    "auth/destroy-single-member",
    async (id, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/destroy-single-member?id=${id}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const res = await axios.request(config)
            if (res?.data?.status == true) {
                customAlert(res?.data?.message, 'success');
            } else {
                customAlert(res?.data?.message);
            }
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);

//Delete multiple member
export const Delete_multiple_member = createAsyncThunk(
    "auth/destroy-multi-member ",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'POST',
                url: `${HTTPURL}api/destroy-multi-member `,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config)
            if (res?.data?.status == true) {
                customAlert(res?.data?.message, 'success');
            } else {
                customAlert(res?.data?.message);
            }
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);


// update member
export const update_member = createAsyncThunk(
    "auth/update-member",
    async (formData, thunkAPI) => {
        const { rejectWithValue } = thunkAPI;
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'POST',
                redirect: 'follow',
                url: `${HTTPURL}api/update-member`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                customAlert(res?.data?.message, 'success');
            } else {
                customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);

//get diagnosis
export const get_diagnosis = createAsyncThunk(
    "/api/diagnosis",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/diagnosis`,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            // if (res?.data?.status == true) {
            //     customAlert(res?.data?.message, 'success');
            // } else {
            //     customAlert(res?.data?.message);
            // }
            return res.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);

//export-excel-file
export const export_excel_file_member = createAsyncThunk(
    "auth/export-excel-file",
    async (_, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/export-excel-file-member`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    });
// upload member image
export const upload_member_image = createAsyncThunk(
    "auth/member-update-profile-pic",
    async (formData, thunkAPI) => {
        const { rejectWithValue } = thunkAPI;
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'POST',
                redirect: 'follow',
                url: `${HTTPURL}api/member-update-profile-pic`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                customAlert(res?.data?.message, 'success');
            } else {
                customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);
//get ddd pcsp data
export const Get_ddd_pcsp_data = createAsyncThunk(
    "auth/get-ddd-pcsp-data",
    async (data, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');

        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/get-ddd-pcsp-data?member_id=${data}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);
// planning meeting member
export const Planning_meeting_member = createAsyncThunk(
    "/api/planning-meeting-member",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/planning-meeting-member`,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                console.log(res?.data?.message, 'success')
                // customAlert(res?.data?.message, 'success');
            } else {
                console.log(res?.data?.message)
                // customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            console.log(error?.response?.data ? error?.response?.data?.message : error?.message)
            // customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);
// responsible person contact info
export const Responsible_person_contact_info = createAsyncThunk(
    "/api/responsible-person-contact-info",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/responsible-person-contact-info`,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                console.log(res?.data?.message, 'success');                
                // customAlert(res?.data?.message, 'success');
            } else {
                console.log(res?.data?.message);                
                // customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            console.log(error?.response?.data ? error?.response?.data?.message : error?.message);
            
            // customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);
// meeting notes
export const Meeting_notes = createAsyncThunk(
    "/api/meeting-notes",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/meeting-notes`,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                console.log(res?.data?.message, 'success');
                
                // customAlert(res?.data?.message, 'success');
            } else {
                console.log(res?.data?.message);
                
                // customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            console.log(error?.response?.data ? error?.response?.data?.message : error?.message);
            
            // customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);
// profile member
export const Profile_member = createAsyncThunk(
    "/api/profile-member",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/profile-member`,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                console.log(res?.data?.message, 'success');
                
                // customAlert(res?.data?.message, 'success');
            } else {
                console.log(res?.data?.message);
                
                // customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            console.log(error?.response?.data ? error?.response?.data?.message : error?.message);
            
            // customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);
// summry-conversation
export const Summry_conversation = createAsyncThunk(
    "/api/summry-conversation",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/summry-conversation`,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                console.log(res?.data?.message, 'success');
                
                // customAlert(res?.data?.message, 'success');
            } else {
                console.log(res?.data?.message);
                
                // customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            console.log(error?.response?.data ? error?.response?.data?.message : error?.message);
            
            // customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);
// medical-support-info
export const Medical_support_info = createAsyncThunk(
    "/api/medical-support-info",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/medical-support-info`,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                console.log(res?.data?.message, 'success');
                
                // customAlert(res?.data?.message, 'success');
            } else {
                console.log(res?.data?.message);
                
                // customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            console.log(error?.response?.data ? error?.response?.data?.message : error?.message);
            
            // customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);
// behavioral-provider-info
export const Behavioral_provider_info = createAsyncThunk(
    "/api/behavioral-provider-info",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/behavioral-provider-info`,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                console.log(res?.data?.message, 'success');
                
                // customAlert(res?.data?.message, 'success');
            } else {
                console.log(res?.data?.message);
                
                // customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            console.log(error?.response?.data ? error?.response?.data?.message : error?.message);
            
            // customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);
// additional-provider
export const Additional_provider = createAsyncThunk(
    "/api/additional-provider",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/additional-provider`,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                console.log(res?.data?.message, 'success');
                
                // customAlert(res?.data?.message, 'success');
            } else {
                console.log(res?.data?.message);
                
                // customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            console.log(error?.response?.data ? error?.response?.data?.message : error?.message);
            
            // customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);
// medications
export const Medications = createAsyncThunk(
    "/api/medications",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/medications`,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                console.log(res?.data?.message, 'success');
                
                // customAlert(res?.data?.message, 'success');
            } else {
                console.log(res?.data?.message);
                
                // customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            console.log(error?.response?.data ? error?.response?.data?.message : error?.message);
            
            // customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);
// vision-hearing-speech
export const Vision_hearing_speech = createAsyncThunk(
    "/api/vision-hearing-speech",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/vision-hearing-speech`,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                console.log(res?.data?.message, 'success');
                
                // customAlert(res?.data?.message, 'success');
            } else {
                console.log(res?.data?.message);
                
                // customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            console.log(error?.response?.data ? error?.response?.data?.message : error?.message);
            
            // customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);
// get vision-hearing-speech-list
export const get_vision_hearing_speech_list = createAsyncThunk(
    "/api/vision-hearing-speech-list",
    async (_, thunkAPI) => {
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/vision-hearing-speech-list`,
                headers: {
                    "Content-Type": "application/json",
                }
            };
            const res = await axios.request(config);
            return res.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
            return rejectWithValue(error?.response?.data);
        }
    }
);
// vision-hearing-speech-equipements
export const Vision_hearing_speech_equipements = createAsyncThunk(
    "/api/vision-hearing-speech-equipements",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/vision-hearing-speech-equipements`,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                console.log(res?.data?.message, 'success');
                
                // customAlert(res?.data?.message, 'success');
            } else {
                console.log(res?.data?.message);
                
                // customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            console.log(error?.response?.data ? error?.response?.data?.message : error?.message);
            
            // customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);
// medical-supplies
export const Medical_supplies = createAsyncThunk(
    "/api/medical-supplies",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/medical-supplies`,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                console.log(res?.data?.message, 'success');
                
                // customAlert(res?.data?.message, 'success');
            } else {
                console.log(res?.data?.message);
                
                // customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            console.log(error?.response?.data ? error?.response?.data?.message : error?.message);
            
            // customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);
// preventative-screening-services
export const Preventative_screening_services = createAsyncThunk(
    "/api/preventative-screening-services",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/preventative-screening-services`,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                console.log(res?.data?.message, 'success');
                
                // customAlert(res?.data?.message, 'success');
            } else {
                console.log(res?.data?.message);
                
                // customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            console.log(error?.response?.data ? error?.response?.data?.message : error?.message);
            
            // customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);
// get prematative-service-list
export const get_prematative_service_list = createAsyncThunk(
    "/api/prematative-service-list",
    async (_, thunkAPI) => {
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/prematative-service-list`,
                headers: {
                    "Content-Type": "application/json",
                }
            };
            const res = await axios.request(config);
            return res.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
            return rejectWithValue(error?.response?.data);
        }
    }
);
// home-life
export const Home_life = createAsyncThunk(
    "/api/home-life",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/home-life`,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                console.log(res?.data?.message, 'success');
                
                // customAlert(res?.data?.message, 'success');
            } else {
                console.log(res?.data?.message);
                
                // customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            console.log(error?.response?.data ? error?.response?.data?.message : error?.message);
            
            // customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);
// daily-life
export const Daily_life = createAsyncThunk(
    "/api/daily-life",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/daily-life`,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                console.log(res?.data?.message, 'success');
                
                // customAlert(res?.data?.message, 'success');
            } else {
                console.log(res?.data?.message);
                
                // customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            console.log(error?.response?.data ? error?.response?.data?.message : error?.message);
            
            // customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);
// individualized-goals-comes
export const Individualized_goals_comes = createAsyncThunk(
    "/api/individualized-goals-comes",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/individualized-goals-comes`,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                console.log(res?.data?.message, 'success');
                
                // customAlert(res?.data?.message, 'success');
            } else {
                console.log(res?.data?.message);
                
                // customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            console.log(error?.response?.data ? error?.response?.data?.message : error?.message);
            
            // customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);
// activities-daily-living
export const Activities_daily_living = createAsyncThunk(
    "/api/activities-daily-living",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/activities-daily-living`,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                console.log(res?.data?.message, 'success');
                
                // customAlert(res?.data?.message, 'success');
            } else {
                console.log(res?.data?.message);
                
                // customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            console.log(error?.response?.data ? error?.response?.data?.message : error?.message);
            
            // customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);
// services-authorized
export const Services_authorized = createAsyncThunk(
    "/api/services-authorized",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/services-authorized`,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                console.log(res?.data?.message, 'success');
                
                // customAlert(res?.data?.message, 'success');
            } else {
                console.log(res?.data?.message);
                
                // customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            console.log(error?.response?.data ? error?.response?.data?.message : error?.message);
            
            // customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);
// services-authorized-non-paid
export const Services_authorized_non_paid = createAsyncThunk(
    "/api/services-authorized-non-paid",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/services-authorized-non-paid`,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                console.log(res?.data?.message, 'success');
                
                // customAlert(res?.data?.message, 'success');
            } else {
                console.log(res?.data?.message);
                
                // customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            console.log(error?.response?.data ? error?.response?.data?.message : error?.message);
            
            // customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);
// services-authorized-non-altcs
export const Services_authorized_non_altcs = createAsyncThunk(
    "/api/services-authorized-non-altcs",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/services-authorized-non-altcs`,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                console.log(res?.data?.message, 'success');
                
                // customAlert(res?.data?.message, 'success');
            } else {
                console.log(res?.data?.message);
                
                // customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            console.log(error?.response?.data ? error?.response?.data?.message : error?.message);
            
            // customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);
//get all-type-risk
export const get_all_type_risk = createAsyncThunk(
    "/api/all-type-risk",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/all-type-risk`,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            // if (res?.data?.status == true) {
            //     customAlert(res?.data?.message, 'success');
            // } else {
            //     customAlert(res?.data?.message);
            // }
            return res.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);
//health-medical-risks
export const Health_medical_risks = createAsyncThunk(
    "/api/health-medical-risks",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/health-medical-risks`,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                console.log(res?.data?.message, 'success');
                
                // customAlert(res?.data?.message, 'success');
            } else {
                console.log(res?.data?.message);
                
                // customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            console.log(error?.response?.data ? error?.response?.data?.message : error?.message);
            
            // customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);
//get selected-option-risks
export const Get_selected_option_risks = createAsyncThunk(
    "auth/selected-option-risks",
    async (data, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');

        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/selected-option-risks?member_id=${data}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);
//risks-assessment
export const Risks_assessment = createAsyncThunk(
    "/api/risks-assessment",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/risks-assessment`,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                console.log(res?.data?.message, 'success');
                
                // customAlert(res?.data?.message, 'success');
            } else {
                console.log(res?.data?.message);
                
                // customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            console.log(error?.response?.data ? error?.response?.data?.message : error?.message);
            
            // customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);
//modifications_plan
export const Modifications_plan = createAsyncThunk(
    "/api/modifications",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/modifications`,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                console.log(res?.data?.message, 'success');
                
                // customAlert(res?.data?.message, 'success');
            } else {
                console.log(res?.data?.message);
                
                // customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            console.log(error?.response?.data ? error?.response?.data?.message : error?.message);
            
            // customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);
//action-plan-followup
export const Action_plan_followup = createAsyncThunk(
    "/api/action-plan-followup",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/action-plan-followup`,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                console.log(res?.data?.message, 'success');
                
                // customAlert(res?.data?.message, 'success');
            } else {
                console.log(res?.data?.message);
                
                // customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            console.log(error?.response?.data ? error?.response?.data?.message : error?.message);
            
            // customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);
//informed-concent
export const Informed_concent = createAsyncThunk(
    "/api/informed-concent",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/informed-concent`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                console.log(res?.data?.message, 'success');
            } else {
                console.log(res?.data?.message);
            }
            return res.data;
        } catch (error) {
             console.log(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);
//next-meeting-info
export const Next_meeting_info = createAsyncThunk(
    "/api/next-meeting-info",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/next-meeting-info`,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                console.log(res?.data?.message, 'success');
            } else {
                console.log(res?.data?.message);
            }
            return res.data;
        } catch (error) { console.log(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);
//get all-provider-types
export const get_all_provider_types = createAsyncThunk(
    "/api/all-provider-types",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/all-provider-types`,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            // if (res?.data?.status == true) {
            //     customAlert(res?.data?.message, 'success');
            // } else {
            //     customAlert(res?.data?.message);
            // }
            return res.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);
//get all-individualized-health-data
export const Get_all_individualized_health_data = createAsyncThunk(
    "auth/all-individualized-health-data",
    async (data, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');

        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/all-individualized-health-data?member_id=${data}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);
//basic-information
export const Basic_information = createAsyncThunk(
    "/api/basic-information",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/basic-information`,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                // customAlert(res?.data?.message, 'success');
            } else {
                // customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            // customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);
//health-medical-first-step
export const Health_medical_first_step = createAsyncThunk(
    "/api/health-medical-first-step",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/health-medical-first-step`,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                // customAlert(res?.data?.message, 'success');
            } else {
                // customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            // customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);
//health-medical-second-step
export const Health_medical_second_step = createAsyncThunk(
    "/api/health-medical-second-step",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/health-medical-second-step`,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                // customAlert(res?.data?.message, 'success');
            } else {
                // customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            // customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);
//health-medical-third-step
export const Health_medical_third_step = createAsyncThunk(
    "/api/health-medical-third-step",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/health-medical-third-step`,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                // customAlert(res?.data?.message, 'success');
            } else {
                // customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            // customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);
//health-medical-fourth-step
export const Health_medical_fourth_step = createAsyncThunk(
    "/api/health-medical-fourth-step",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/health-medical-fourth-step`,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                // customAlert(res?.data?.message, 'success');
            } else {
                // customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            // customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);
//diet-food
export const Diet_food = createAsyncThunk(
    "/api/diet-food",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/diet-food`,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                // customAlert(res?.data?.message, 'success');
            } else {
                // customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            // customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);
//diet-beverage
export const Diet_beverage = createAsyncThunk(
    "/api/diet-beverage",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/diet-beverage`,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                // customAlert(res?.data?.message, 'success');
            } else {
                // customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            // customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);
//communication
export const Communication_form = createAsyncThunk(
    "/api/communication",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/communication`,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                // customAlert(res?.data?.message, 'success');
            } else {
                // customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            // customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);
//mobility
export const Mobility_form = createAsyncThunk(
    "/api/mobility",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/mobility`,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                // customAlert(res?.data?.message, 'success');
            } else {
                // customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            // customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);
//personal-care-skills
export const Personal_care_skills = createAsyncThunk(
    "/api/personal-care-skills",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/personal-care-skills`,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                // customAlert(res?.data?.message, 'success');
            } else {
                // customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            // customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);
//behavioral-concern
export const Behavioral_concern = createAsyncThunk(
    "/api/behavioral-concern",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/behavioral-concern`,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                // customAlert(res?.data?.message, 'success');
            } else {
                // customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            // customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);
// get all-medical-disability
export const get_all_medical_disability = createAsyncThunk(
    "/api/all-medical-disability",
    async (_, thunkAPI) => {
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/all-medical-disability`,
                headers: {
                    "Content-Type": "application/json",
                }
            };
            const res = await axios.request(config);
            return res.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
            return rejectWithValue(error?.response?.data);
        }
    }
);
// get all-medical-diseases
export const get_all_medical_diseases = createAsyncThunk(
    "/api/all-medical-diseases",
    async (_, thunkAPI) => {
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/all-medical-diseases`,
                headers: {
                    "Content-Type": "application/json",
                }
            };
            const res = await axios.request(config);
            return res.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
            return rejectWithValue(error?.response?.data);
        }
    }
);
//get all-medical-history
export const Get_all_medical_history = createAsyncThunk(
    "auth/all-medical-history",
    async (data, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');

        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/all-medical-history?member_id=${data}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);
//medical-history
export const medical_history = createAsyncThunk(
    "/api/medical-history",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/medical-history`,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                // customAlert(res?.data?.message, 'success');
            } else {
                // customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            // customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);
//medical-history-allergie
export const Medical_history_allergie = createAsyncThunk(
    "/api/medical-history-allergie",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/medical-history-allergie`,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                // customAlert(res?.data?.message, 'success');
            } else {
                // customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            // customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);
//medical-history-current-condition
export const Medical_history_current_condition = createAsyncThunk(
    "/api/medical-history-current-condition",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/medical-history-current-condition`,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                // customAlert(res?.data?.message, 'success');
            } else {
                // customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            // customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);
//medical-history-treatment
export const Medical_history_treatment = createAsyncThunk(
    "/api/medical-history-treatment",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/medical-history-treatment`,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                // customAlert(res?.data?.message, 'success');
            } else {
                // customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            // customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);
//get all-behaviour-modifying-medication
export const Get_all_behaviour_modifying_medication = createAsyncThunk(
    "auth/all-behaviour-modifying-medication",
    async (data, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');

        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/all-behaviour-modifying-medication?member_id=${data}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);
//behavior-modifying-medication-consent
export const Behavior_modifying_medication_consent = createAsyncThunk(
    "/api/behavior-modifying-medication-consent",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/behavior-modifying-medication-consent`,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                // customAlert(res?.data?.message, 'success');
            } else {
                // customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            // customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);
//get step-form-complete-or-not
export const Get_step_form_complete_or_not = createAsyncThunk(
    "auth/step-form-complete-or-not",
    async (data, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');

        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/step-form-complete-or-not?member_id=${data}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);
//get all-general-consent-authorization
export const Get_all_general_consent_authorization = createAsyncThunk(
    "auth/all-general-consent-authorization",
    async (data, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');

        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/all-general-consent-authorization?member_id=${data}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);
//general-consent-authorization
export const General_consent_authorization = createAsyncThunk(
    "/api/general-consent-authorization",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/general-consent-authorization`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData,
                redirect:"follow"
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                customAlert(res?.data?.message, 'success');
            } else {
                customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);
//upload-pdf
export const upload_pdf_dddpcsp = createAsyncThunk(
    "/api/upload-pdf",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/upload-pdf`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData,
                redirect:"follow"
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                customAlert(res?.data?.message, 'success');
            } else {
                customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);