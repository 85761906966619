import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UploadAndManually from './UploadAndManually';
import { useDispatch, useSelector } from 'react-redux';
import { Get_step_form_complete_or_not } from '../../../redux/services/AuthMembers';

const FORMS = [
    { title: 'DDD PCSP', type: 'ddd_pcsp', url: '/auth/edit-members/ddd/pcsp/' },
    { title: 'Individualized health care & safety needs', type: 'Individualized_health', url: '/auth/edit-members/individualized/' },
    { title: 'Medical history', type: 'Medical_history', url: '/auth/edit-members/medicalhistory/' },
    { title: 'General consent and authorization', type: 'General_Consent', url: '/auth/edit-members/general-consent/' },
    { title: 'Behavior modifying medication consent', type: 'Medication_Consent', url: '/auth/edit-members/behavior-modifying/' }
];

export const Forms = ({ member_id }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const totalSteps = 13;
    const stepFormComplete = useSelector((state) => state?.AuthMember?.stepFormComplete);
    const [uploadAndManuallyModal, setUploadAndManuallyModal] = useState(false);
    const [completedForms, setCompletedForms] = useState({});
    const [isIndividualizedHealthEnabled, setIsIndividualizedHealthEnabled] = useState(true);
    const [isOtherFormsEnabled, setIsOtherFormsEnabled] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [progressPercentage, setProgressPercentage] = useState(0);

    useEffect(() => {
        dispatch(Get_step_form_complete_or_not(member_id));
    }, [dispatch, member_id]);

    useEffect(() => {                
        if (stepFormComplete) {
            let newData = {};
            stepFormComplete.forEach((item) => {                
                newData[item.form_type] = !item.profile_status;

                // Control logic for enabling/disabling forms
                if (item.form_type === 'ddd_pcsp') {
                    setCurrentStep(Number(item.step_no));
                    setProgressPercentage((Number(item.step_no) / totalSteps) * 100);

                    if (!item.profile_status) {
                        setIsIndividualizedHealthEnabled(item.profile_status);
                    }
                }

                if (item.form_type === 'Individualized_health') {
                    if (!item.profile_status && completedForms['ddd_pcsp']) {
                        setIsOtherFormsEnabled(true);
                    }
                }
            });
            setCompletedForms(newData);
        }
    }, [stepFormComplete]);

    const handleFormNavigate = (url) => {
        setUploadAndManuallyModal(true);
    };

    const renderFormSection = (title, formKey, navigateUrl) => {        
        return (
            <div className="memb_mult_field_main" key={formKey}>
                <div className="member_field_left">
                    <h4>{title} <span className={`mem_field_bages ${completedForms[formKey] ? 'comp_badge' : ''}`}>{completedForms[formKey] ? 'Completed' : 'Not submitted'}</span></h4>
                </div>
                <div className="member_field_right">
                    {completedForms[formKey] ? (
                        <button type="button" onClick={() => navigate(navigateUrl)}>Edit</button>
                    ) : (
                        <button
                            type="button"
                            onClick={() => formKey === 'ddd_pcsp' ? handleFormNavigate() : navigate(navigateUrl)}
                            disabled={
                                (formKey === 'Individualized_health' ? isIndividualizedHealthEnabled : false ||
                                !completedForms['Individualized_health'] && !isOtherFormsEnabled && formKey !== 'ddd_pcsp')
                            }
                        >
                            Begin
                        </button>
                    )}
                </div>
            </div>
        );
    };

    return (
        <div className="tab-pane active show" id="form" role="tabpanel" aria-labelledby="form-tab">
            <div className="member_vital_form_cont">
                <div className="vital_form_progress">
                    <div className="vital_form_progress_inner">
                        <h4>Complete upload of DDD/PCSP form</h4>
                        <div className="steps-progress">
                            <div className="progress">
                                <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{ width: `${progressPercentage}%` }}
                                    aria-valuenow={progressPercentage}
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                >
                                    {Math.round(progressPercentage)}%
                                </div>
                            </div>
                            <h5 className="sap-card-title">STEP {currentStep} OF {totalSteps}</h5>
                        </div>
                    </div>
                    <div className="vital_form_com_btn">
                        <button type="button" className="btn btn-primary" onClick={() => handleFormNavigate()}>
                            Complete now
                        </button>
                    </div>
                </div>

                {FORMS.map((item) => renderFormSection(item.title, item.type, `${item.url}${member_id}`))}
            </div>
            {/* <div className="member_health_head">
                    <h4>Individualized health care & safety needs</h4>
                    <svg width="12" height="8" viewBox="0 0 12 8" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M11 6.5L6 1.5L1 6.5" stroke="#0A0D14"
                            stroke-width="1.66667" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
                </div> */}
            {/* <form action="" className="row">
                    <div className="col-md-12 col-12 mb-3">
                        <label for="" className="form-label">Name of assessor</label>
                        <input type="input" className="form-control" id=""
                            placeholder="Enter name" />
                    </div>
                    <div className="col-md-12 col-12 mb-3">
                        <label for="" className="form-label">Date of assessment</label>
                        <input type="input" className="form-control" id=""
                            placeholder="mm/dd/yyyy" />
                    </div>
                    <div className="col-md-12 col-12 mb-3">
                        <label for="" className="form-label">Allergies/Med side
                            effects</label>
                        <select className="form-select"
                            aria-label="Default select example">
                            <option selected="">Yes</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </select>
                    </div>
                    <div className="col-md-12 col-12 mb-3">
                        <label for="" className="form-label">To food</label>
                        <input type="text" className="form-control" id=""
                            placeholder="What food(s) are they allergic to" />
                    </div>

                    <div className="col-md-12 col-12 mb-3">
                        <label for="" className="form-label">To medication</label>
                        <input type="input" className="form-control" id=""
                            placeholder="What mediation(s) are they allergic to" />
                    </div>
                    <div className="col-md-12 col-12 mb-3">
                        <label for="" className="form-label">To bee stings</label>
                        <input type="text" className="form-control" id=""
                            placeholder="Are they allergic to bee stings?" />
                    </div>
                    <div className="col-md-12 col-12 mb-3">
                        <label for="" className="form-label">Other</label>
                        <input type="input" className="form-control" id=""
                            placeholder="What other things are they allergic to?" />
                    </div>


                    <div className="col-12 mb-3">
                        <label for="" className="form-label">Recommended response to
                            allergic reaction</label>
                        <input type="text" className="form-control pb-5" id=""
                            placeholder="Explain what to do when an allergic reaction occurs" />
                    </div>

                    <div className="col-md-12 login-btn ">
                        <button type="button" className="btn btn-outline-primary">View
                            complete information<svg width="10" height="10"
                                viewBox="0 0 10 10" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M0.833313 9.16659L9.16665 0.833252M9.16665 0.833252H0.833313M9.16665 0.833252V9.16659"
                                    stroke="#2342BF" stroke-width="1.66667"
                                    stroke-linecap="round" stroke-linejoin="round">
                                </path>
                            </svg>
                        </button>
                    </div>

                    <hr className="my-3" />
                    <div className="member_health_head">
                        <h4>Medical history</h4>
                        <svg width="12" height="8" viewBox="0 0 12 8" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M11 6.5L6 1.5L1 6.5" stroke="#0A0D14"
                                stroke-width="1.66667" stroke-linecap="round"
                                stroke-linejoin="round" />
                        </svg>
                    </div>

                    <div className="col-12">
                        <div className="grade_content">
                            <h2>Developmental disability</h2>
                            <div className="date-slot activity-sec">
                                <ul className="btn-group" role="group"
                                    aria-label="Basic checkbox toggle button group">
                                    <li className="list-group-item">
                                        <input type="checkbox" className="btn-check"
                                            id="btncheck19" autocomplete="off" />
                                        <label className="btn btn-outline-primary"
                                            for="btncheck19">Anemia</label>
                                    </li>
                                    <li className="list-group-item">
                                        <input type="checkbox" className="btn-check"
                                            id="btncheck20" autocomplete="off" />
                                        <label className="btn btn-outline-primary"
                                            for="btncheck20">Cancer</label>
                                    </li>
                                    <li className="list-group-item">
                                        <input type="checkbox" className="btn-check"
                                            id="btncheck31" autocomplete="off" />
                                        <label className="btn btn-outline-primary"
                                            for="btncheck31">Bronchitis</label>
                                    </li>
                                    <li className="list-group-item">
                                        <input type="checkbox" className="btn-check"
                                            id="btncheck41" autocomplete="off" />
                                        <label className="btn btn-outline-primary"
                                            for="btncheck41">Diabetes</label>
                                    </li>
                                    <li className="list-group-item">
                                        <input type="checkbox" className="btn-check"
                                            id="btncheck51" autocomplete="off" />
                                        <label className="btn btn-outline-primary"
                                            for="btncheck51">Gall bladder
                                            problems</label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-12 mb-3">
                        <label for="" className="form-label">Other (Specify other
                            developmental disability)</label>
                        <input type="text" className="form-control" id=""
                            placeholder="other" />
                    </div>
                    <div className="col-12 mb-3">
                        <label for="" className="form-label">Developmental history (Not
                            required if over 18 years of age)</label>
                        <input type="text" className="form-control pb-5" id=""
                            placeholder="Diana Milone" />
                    </div>
                    <div className="col-12">
                        <div className="grade_content">
                            <h2>Family medical history</h2>
                            <div className="date-slot activity-sec">
                                <ul className="btn-group" role="group"
                                    aria-label="Basic checkbox toggle button group">
                                    <li className="list-group-item">
                                        <input type="checkbox" className="btn-check"
                                            id="btncheck1" autocomplete="off" />
                                        <label className="btn btn-outline-primary"
                                            for="btncheck1">Anemia</label>
                                    </li>
                                    <li className="list-group-item">
                                        <input type="checkbox" className="btn-check"
                                            id="btncheck2" autocomplete="off" />
                                        <label className="btn btn-outline-primary"
                                            for="btncheck2">Cancer</label>
                                    </li>
                                    <li className="list-group-item">
                                        <input type="checkbox" className="btn-check"
                                            id="btncheck3" autocomplete="off" />
                                        <label className="btn btn-outline-primary"
                                            for="btncheck3">Bronchitis</label>
                                    </li>
                                    <li className="list-group-item">
                                        <input type="checkbox" className="btn-check"
                                            id="btncheck4" autocomplete="off" />
                                        <label className="btn btn-outline-primary"
                                            for="btncheck4">Diabetes</label>
                                    </li>
                                    <li className="list-group-item">
                                        <input type="checkbox" className="btn-check"
                                            id="btncheck5" autocomplete="off" />
                                        <label className="btn btn-outline-primary"
                                            for="btncheck5">Gall bladder
                                            problems</label>
                                    </li>
                                    <li className="list-group-item">
                                        <input type="checkbox" className="btn-check"
                                            id="btncheck6" autocomplete="off" />
                                        <label className="btn btn-outline-primary"
                                            for="btncheck6">Gastritis</label>
                                    </li>
                                    <li className="list-group-item">
                                        <input type="checkbox" className="btn-check"
                                            id="btncheck7" autocomplete="off" />
                                        <label className="btn btn-outline-primary"
                                            for="btncheck7">Heart
                                            disease/Murmur</label>
                                    </li>
                                    <li className="list-group-item">
                                        <input type="checkbox" className="btn-check"
                                            id="btncheck8" autocomplete="off" />
                                        <label className="btn btn-outline-primary"
                                            for="btncheck8">Hepatitis</label>
                                    </li>
                                    <li className="list-group-item">
                                        <input type="checkbox" className="btn-check"
                                            id="btncheck9" autocomplete="off" />
                                        <label className="btn btn-outline-primary"
                                            for="btncheck9">High blood
                                            pressure</label>
                                    </li>
                                    <li className="list-group-item">
                                        <input type="checkbox" className="btn-check"
                                            id="btncheck10" autocomplete="off" />
                                        <label className="btn btn-outline-primary"
                                            for="btncheck10">Immune surpressed
                                            disorder</label>
                                    </li>
                                    <li className="list-group-item">
                                        <input type="checkbox" className="btn-check"
                                            id="btncheck11" autocomplete="off" />
                                        <label className="btn btn-outline-primary"
                                            for="btncheck11">Mental illness</label>
                                    </li>
                                    <li className="list-group-item">
                                        <input type="checkbox" className="btn-check"
                                            id="btncheck12" autocomplete="off" />
                                        <label className="btn btn-outline-primary"
                                            for="btncheck12">Polio</label>
                                    </li>
                                    <li className="list-group-item">
                                        <input type="checkbox" className="btn-check"
                                            id="btncheck13" autocomplete="off" />
                                        <label className="btn btn-outline-primary"
                                            for="btncheck13">Substance abuse</label>
                                    </li>
                                    <li className="list-group-item">
                                        <input type="checkbox" className="btn-check"
                                            id="btncheck14" autocomplete="off" />
                                        <label className="btn btn-outline-primary"
                                            for="btncheck14">Thyroid
                                            disorder</label>
                                    </li>
                                    <li className="list-group-item">
                                        <input type="checkbox" className="btn-check"
                                            id="btncheck15" autocomplete="off" />
                                        <label className="btn btn-outline-primary"
                                            for="btncheck15">Tuberculosis</label>
                                    </li>
                                    <li className="list-group-item">
                                        <input type="checkbox" className="btn-check"
                                            id="btncheck16" autocomplete="off" />
                                        <label className="btn btn-outline-primary"
                                            for="btncheck16">Ulcers</label>
                                    </li>
                                    <li className="list-group-item">
                                        <input type="checkbox" className="btn-check"
                                            id="btncheck17" autocomplete="off" />
                                        <label className="btn btn-outline-primary"
                                            for="btncheck17">No known concerns
                                            regarding the family's medical
                                            history</label>
                                    </li>
                                    <li className="list-group-item">
                                        <input type="checkbox" className="btn-check"
                                            id="btncheck18" autocomplete="off" />
                                        <label className="btn btn-outline-primary"
                                            for="btncheck18">Family medical history
                                            is not available</label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 login-btn ">
                        <button type="button" className="btn btn-outline-primary">View
                            complete information<svg width="10" height="10"
                                viewBox="0 0 10 10" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M0.833313 9.16659L9.16665 0.833252M9.16665 0.833252H0.833313M9.16665 0.833252V9.16659"
                                    stroke="#2342BF" stroke-width="1.66667"
                                    stroke-linecap="round" stroke-linejoin="round">
                                </path>
                            </svg>
                        </button>
                    </div>
                    <hr className="my-3" />
                </form> */}
            <UploadAndManually id={member_id} isOpen={uploadAndManuallyModal} setIsOpen={setUploadAndManuallyModal} />
        </div>
    );
};
