import React from 'react'

export const Communication = () => {
  return (
    <div className="tab-pane fade vital_comn active show" id="role" role="tabpanel"
    aria-labelledby="role-tab">
    <div className="row">
        <div className="col-md-12 col-12">
            <div className="table-sec">
                <div className="table-responsive">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th scope="col">Communication Logs</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Urgent Surgery Required<span
                                    className="d-block">Jospine Greta has
                                    been unwell for awhile now
                                    and...</span></td>
                                <td>Mar 10 <span className="d-block">08:15
                                    PM</span></td>
                            </tr>
                            <tr>
                                <td>Urgent Surgery Required<span
                                    className="d-block">Jospine Greta has
                                    been unwell for awhile now
                                    and...</span>
                                    <div className="mvital_pdf_btn">
                                        <button type="button"><svg
                                            width="26" height="16"
                                            viewBox="0 0 26 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M0 2C0 0.895431 0.895431 0 2 0H24C25.1046 0 26 0.895431 26 2V14C26 15.1046 25.1046 16 24 16H2C0.89543 16 0 15.1046 0 14V2Z"
                                                fill="#D92D20" />
                                            <path
                                                d="M3.8323 12V4.72727H6.70162C7.25323 4.72727 7.72316 4.83262 8.11142 5.04332C8.49967 5.25166 8.7956 5.54167 8.9992 5.91335C9.20517 6.28267 9.30815 6.70881 9.30815 7.19176C9.30815 7.67472 9.20398 8.10085 8.99565 8.47017C8.78732 8.83949 8.48547 9.12713 8.09011 9.3331C7.69712 9.53906 7.22127 9.64205 6.66255 9.64205H4.83372V8.4098H6.41397C6.7099 8.4098 6.95375 8.3589 7.14551 8.2571C7.33964 8.15294 7.48405 8.00971 7.57875 7.82741C7.67581 7.64276 7.72434 7.43087 7.72434 7.19176C7.72434 6.95028 7.67581 6.73958 7.57875 6.55966C7.48405 6.37737 7.33964 6.23651 7.14551 6.13707C6.95138 6.03527 6.70517 5.98438 6.40687 5.98438H5.36994V12H3.8323ZM12.885 12H10.3069V4.72727H12.9063C13.6379 4.72727 14.2676 4.87287 14.7955 5.16406C15.3235 5.45289 15.7295 5.86837 16.0136 6.41051C16.3 6.95265 16.4433 7.60133 16.4433 8.35653C16.4433 9.11411 16.3 9.76515 16.0136 10.3097C15.7295 10.8542 15.3211 11.272 14.7884 11.5632C14.2581 11.8544 13.6237 12 12.885 12ZM11.8445 10.6825H12.8211C13.2757 10.6825 13.658 10.602 13.9681 10.4411C14.2806 10.2777 14.515 10.0256 14.6713 9.68466C14.8299 9.34138 14.9092 8.89867 14.9092 8.35653C14.9092 7.81913 14.8299 7.37997 14.6713 7.03906C14.515 6.69815 14.2818 6.44721 13.9717 6.28622C13.6615 6.12524 13.2792 6.04474 12.8247 6.04474H11.8445V10.6825ZM17.5823 12V4.72727H22.3976V5.99503H19.1199V7.72798H22.078V8.99574H19.1199V12H17.5823Z"
                                                fill="white" />
                                        </svg> X-Ray Samples.png
                                        </button>
                                        <button type="button"><svg
                                            width="26" height="16"
                                            viewBox="0 0 26 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M0 2C0 0.895431 0.895431 0 2 0H24C25.1046 0 26 0.895431 26 2V14C26 15.1046 25.1046 16 24 16H2C0.89543 16 0 15.1046 0 14V2Z"
                                                fill="#D92D20" />
                                            <path
                                                d="M3.8323 12V4.72727H6.70162C7.25323 4.72727 7.72316 4.83262 8.11142 5.04332C8.49967 5.25166 8.7956 5.54167 8.9992 5.91335C9.20517 6.28267 9.30815 6.70881 9.30815 7.19176C9.30815 7.67472 9.20398 8.10085 8.99565 8.47017C8.78732 8.83949 8.48547 9.12713 8.09011 9.3331C7.69712 9.53906 7.22127 9.64205 6.66255 9.64205H4.83372V8.4098H6.41397C6.7099 8.4098 6.95375 8.3589 7.14551 8.2571C7.33964 8.15294 7.48405 8.00971 7.57875 7.82741C7.67581 7.64276 7.72434 7.43087 7.72434 7.19176C7.72434 6.95028 7.67581 6.73958 7.57875 6.55966C7.48405 6.37737 7.33964 6.23651 7.14551 6.13707C6.95138 6.03527 6.70517 5.98438 6.40687 5.98438H5.36994V12H3.8323ZM12.885 12H10.3069V4.72727H12.9063C13.6379 4.72727 14.2676 4.87287 14.7955 5.16406C15.3235 5.45289 15.7295 5.86837 16.0136 6.41051C16.3 6.95265 16.4433 7.60133 16.4433 8.35653C16.4433 9.11411 16.3 9.76515 16.0136 10.3097C15.7295 10.8542 15.3211 11.272 14.7884 11.5632C14.2581 11.8544 13.6237 12 12.885 12ZM11.8445 10.6825H12.8211C13.2757 10.6825 13.658 10.602 13.9681 10.4411C14.2806 10.2777 14.515 10.0256 14.6713 9.68466C14.8299 9.34138 14.9092 8.89867 14.9092 8.35653C14.9092 7.81913 14.8299 7.37997 14.6713 7.03906C14.515 6.69815 14.2818 6.44721 13.9717 6.28622C13.6615 6.12524 13.2792 6.04474 12.8247 6.04474H11.8445V10.6825ZM17.5823 12V4.72727H22.3976V5.99503H19.1199V7.72798H22.078V8.99574H19.1199V12H17.5823Z"
                                                fill="white" />
                                        </svg> Brain Scan Image...
                                        </button>
                                    </div>
                                </td>
                                <td>Mar 10 <span className="d-block">08:15
                                    PM</span></td>

                            </tr>
                            <tr>
                                <td>Urgent Surgery Required<span
                                    className="d-block">Jospine Greta has
                                    been unwell for awhile now
                                    and...</span></td>
                                <td>Mar 10 <span className="d-block">08:15
                                    PM</span></td>
                            </tr>
                            <tr>
                                <td>Urgent Surgery Required<span
                                    className="d-block">Jospine Greta has
                                    been unwell for awhile now
                                    and...</span>
                                    <div className="mvital_pdf_btn">
                                        <button type="button"><svg
                                            width="26" height="16"
                                            viewBox="0 0 26 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M0 2C0 0.895431 0.895431 0 2 0H24C25.1046 0 26 0.895431 26 2V14C26 15.1046 25.1046 16 24 16H2C0.89543 16 0 15.1046 0 14V2Z"
                                                fill="#D92D20" />
                                            <path
                                                d="M3.8323 12V4.72727H6.70162C7.25323 4.72727 7.72316 4.83262 8.11142 5.04332C8.49967 5.25166 8.7956 5.54167 8.9992 5.91335C9.20517 6.28267 9.30815 6.70881 9.30815 7.19176C9.30815 7.67472 9.20398 8.10085 8.99565 8.47017C8.78732 8.83949 8.48547 9.12713 8.09011 9.3331C7.69712 9.53906 7.22127 9.64205 6.66255 9.64205H4.83372V8.4098H6.41397C6.7099 8.4098 6.95375 8.3589 7.14551 8.2571C7.33964 8.15294 7.48405 8.00971 7.57875 7.82741C7.67581 7.64276 7.72434 7.43087 7.72434 7.19176C7.72434 6.95028 7.67581 6.73958 7.57875 6.55966C7.48405 6.37737 7.33964 6.23651 7.14551 6.13707C6.95138 6.03527 6.70517 5.98438 6.40687 5.98438H5.36994V12H3.8323ZM12.885 12H10.3069V4.72727H12.9063C13.6379 4.72727 14.2676 4.87287 14.7955 5.16406C15.3235 5.45289 15.7295 5.86837 16.0136 6.41051C16.3 6.95265 16.4433 7.60133 16.4433 8.35653C16.4433 9.11411 16.3 9.76515 16.0136 10.3097C15.7295 10.8542 15.3211 11.272 14.7884 11.5632C14.2581 11.8544 13.6237 12 12.885 12ZM11.8445 10.6825H12.8211C13.2757 10.6825 13.658 10.602 13.9681 10.4411C14.2806 10.2777 14.515 10.0256 14.6713 9.68466C14.8299 9.34138 14.9092 8.89867 14.9092 8.35653C14.9092 7.81913 14.8299 7.37997 14.6713 7.03906C14.515 6.69815 14.2818 6.44721 13.9717 6.28622C13.6615 6.12524 13.2792 6.04474 12.8247 6.04474H11.8445V10.6825ZM17.5823 12V4.72727H22.3976V5.99503H19.1199V7.72798H22.078V8.99574H19.1199V12H17.5823Z"
                                                fill="white" />
                                        </svg> X-Ray Samples.png
                                        </button>
                                        <button type="button"><svg
                                            width="26" height="16"
                                            viewBox="0 0 26 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M0 2C0 0.895431 0.895431 0 2 0H24C25.1046 0 26 0.895431 26 2V14C26 15.1046 25.1046 16 24 16H2C0.89543 16 0 15.1046 0 14V2Z"
                                                fill="#D92D20" />
                                            <path
                                                d="M3.8323 12V4.72727H6.70162C7.25323 4.72727 7.72316 4.83262 8.11142 5.04332C8.49967 5.25166 8.7956 5.54167 8.9992 5.91335C9.20517 6.28267 9.30815 6.70881 9.30815 7.19176C9.30815 7.67472 9.20398 8.10085 8.99565 8.47017C8.78732 8.83949 8.48547 9.12713 8.09011 9.3331C7.69712 9.53906 7.22127 9.64205 6.66255 9.64205H4.83372V8.4098H6.41397C6.7099 8.4098 6.95375 8.3589 7.14551 8.2571C7.33964 8.15294 7.48405 8.00971 7.57875 7.82741C7.67581 7.64276 7.72434 7.43087 7.72434 7.19176C7.72434 6.95028 7.67581 6.73958 7.57875 6.55966C7.48405 6.37737 7.33964 6.23651 7.14551 6.13707C6.95138 6.03527 6.70517 5.98438 6.40687 5.98438H5.36994V12H3.8323ZM12.885 12H10.3069V4.72727H12.9063C13.6379 4.72727 14.2676 4.87287 14.7955 5.16406C15.3235 5.45289 15.7295 5.86837 16.0136 6.41051C16.3 6.95265 16.4433 7.60133 16.4433 8.35653C16.4433 9.11411 16.3 9.76515 16.0136 10.3097C15.7295 10.8542 15.3211 11.272 14.7884 11.5632C14.2581 11.8544 13.6237 12 12.885 12ZM11.8445 10.6825H12.8211C13.2757 10.6825 13.658 10.602 13.9681 10.4411C14.2806 10.2777 14.515 10.0256 14.6713 9.68466C14.8299 9.34138 14.9092 8.89867 14.9092 8.35653C14.9092 7.81913 14.8299 7.37997 14.6713 7.03906C14.515 6.69815 14.2818 6.44721 13.9717 6.28622C13.6615 6.12524 13.2792 6.04474 12.8247 6.04474H11.8445V10.6825ZM17.5823 12V4.72727H22.3976V5.99503H19.1199V7.72798H22.078V8.99574H19.1199V12H17.5823Z"
                                                fill="white" />
                                        </svg> Brain Scan Image...
                                        </button>
                                    </div>
                                </td>
                                <td>Mar 10 <span className="d-block">08:15
                                    PM</span></td>

                            </tr>
                            <tr>
                                <td>Urgent Surgery Required<span
                                    className="d-block">Jospine Greta has
                                    been unwell for awhile now
                                    and...</span></td>
                                <td>Mar 10 <span className="d-block">08:15
                                    PM</span></td>
                            </tr>
                            <tr>
                                <td>Urgent Surgery Required<span
                                    className="d-block">Jospine Greta has
                                    been unwell for awhile now
                                    and...</span>
                                    <div className="mvital_pdf_btn">
                                        <button type="button"><svg
                                            width="26" height="16"
                                            viewBox="0 0 26 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M0 2C0 0.895431 0.895431 0 2 0H24C25.1046 0 26 0.895431 26 2V14C26 15.1046 25.1046 16 24 16H2C0.89543 16 0 15.1046 0 14V2Z"
                                                fill="#D92D20" />
                                            <path
                                                d="M3.8323 12V4.72727H6.70162C7.25323 4.72727 7.72316 4.83262 8.11142 5.04332C8.49967 5.25166 8.7956 5.54167 8.9992 5.91335C9.20517 6.28267 9.30815 6.70881 9.30815 7.19176C9.30815 7.67472 9.20398 8.10085 8.99565 8.47017C8.78732 8.83949 8.48547 9.12713 8.09011 9.3331C7.69712 9.53906 7.22127 9.64205 6.66255 9.64205H4.83372V8.4098H6.41397C6.7099 8.4098 6.95375 8.3589 7.14551 8.2571C7.33964 8.15294 7.48405 8.00971 7.57875 7.82741C7.67581 7.64276 7.72434 7.43087 7.72434 7.19176C7.72434 6.95028 7.67581 6.73958 7.57875 6.55966C7.48405 6.37737 7.33964 6.23651 7.14551 6.13707C6.95138 6.03527 6.70517 5.98438 6.40687 5.98438H5.36994V12H3.8323ZM12.885 12H10.3069V4.72727H12.9063C13.6379 4.72727 14.2676 4.87287 14.7955 5.16406C15.3235 5.45289 15.7295 5.86837 16.0136 6.41051C16.3 6.95265 16.4433 7.60133 16.4433 8.35653C16.4433 9.11411 16.3 9.76515 16.0136 10.3097C15.7295 10.8542 15.3211 11.272 14.7884 11.5632C14.2581 11.8544 13.6237 12 12.885 12ZM11.8445 10.6825H12.8211C13.2757 10.6825 13.658 10.602 13.9681 10.4411C14.2806 10.2777 14.515 10.0256 14.6713 9.68466C14.8299 9.34138 14.9092 8.89867 14.9092 8.35653C14.9092 7.81913 14.8299 7.37997 14.6713 7.03906C14.515 6.69815 14.2818 6.44721 13.9717 6.28622C13.6615 6.12524 13.2792 6.04474 12.8247 6.04474H11.8445V10.6825ZM17.5823 12V4.72727H22.3976V5.99503H19.1199V7.72798H22.078V8.99574H19.1199V12H17.5823Z"
                                                fill="white" />
                                        </svg> X-Ray Samples.png
                                        </button>
                                        <button type="button"><svg
                                            width="26" height="16"
                                            viewBox="0 0 26 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M0 2C0 0.895431 0.895431 0 2 0H24C25.1046 0 26 0.895431 26 2V14C26 15.1046 25.1046 16 24 16H2C0.89543 16 0 15.1046 0 14V2Z"
                                                fill="#D92D20" />
                                            <path
                                                d="M3.8323 12V4.72727H6.70162C7.25323 4.72727 7.72316 4.83262 8.11142 5.04332C8.49967 5.25166 8.7956 5.54167 8.9992 5.91335C9.20517 6.28267 9.30815 6.70881 9.30815 7.19176C9.30815 7.67472 9.20398 8.10085 8.99565 8.47017C8.78732 8.83949 8.48547 9.12713 8.09011 9.3331C7.69712 9.53906 7.22127 9.64205 6.66255 9.64205H4.83372V8.4098H6.41397C6.7099 8.4098 6.95375 8.3589 7.14551 8.2571C7.33964 8.15294 7.48405 8.00971 7.57875 7.82741C7.67581 7.64276 7.72434 7.43087 7.72434 7.19176C7.72434 6.95028 7.67581 6.73958 7.57875 6.55966C7.48405 6.37737 7.33964 6.23651 7.14551 6.13707C6.95138 6.03527 6.70517 5.98438 6.40687 5.98438H5.36994V12H3.8323ZM12.885 12H10.3069V4.72727H12.9063C13.6379 4.72727 14.2676 4.87287 14.7955 5.16406C15.3235 5.45289 15.7295 5.86837 16.0136 6.41051C16.3 6.95265 16.4433 7.60133 16.4433 8.35653C16.4433 9.11411 16.3 9.76515 16.0136 10.3097C15.7295 10.8542 15.3211 11.272 14.7884 11.5632C14.2581 11.8544 13.6237 12 12.885 12ZM11.8445 10.6825H12.8211C13.2757 10.6825 13.658 10.602 13.9681 10.4411C14.2806 10.2777 14.515 10.0256 14.6713 9.68466C14.8299 9.34138 14.9092 8.89867 14.9092 8.35653C14.9092 7.81913 14.8299 7.37997 14.6713 7.03906C14.515 6.69815 14.2818 6.44721 13.9717 6.28622C13.6615 6.12524 13.2792 6.04474 12.8247 6.04474H11.8445V10.6825ZM17.5823 12V4.72727H22.3976V5.99503H19.1199V7.72798H22.078V8.99574H19.1199V12H17.5823Z"
                                                fill="white" />
                                        </svg> Brain Scan Image...
                                        </button>
                                    </div>
                                </td>
                                <td>Mar 10 <span className="d-block">08:15
                                    PM</span></td>
                            </tr>
                            <tr>
                                <td colspan="7">
                                    <div className="paging">
                                        <button type="button"
                                            className="btn btn-light">Previous</button>
                                        <div className="page-num">Page 1 of 10
                                        </div>
                                        <button type="button"
                                            className="btn btn-light">Next</button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}
