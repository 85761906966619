import { createSlice } from "@reduxjs/toolkit";
import { getCountry,getState,get_all_roles,get_languages } from "../services/common";

const initialState={
    isLoading: false,
    error: null,
    CountryData: [],
    StateData: [],
    RoleData:[],
    DataTableCheck:[],
    moduleData:[],
    languageData:[]
}


const common = createSlice({
    name: 'common',
    initialState,
    reducers: {
        setDataTableCheck: (state, action) => {
            state.DataTableCheck = action?.payload;
        },
        setModuleData: (state, action) => {
            state.moduleData = action?.payload;
        },
    },
    extraReducers: (builder) => {
        // ===========Country Data============== //
        builder.addCase(getCountry.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(getCountry.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.CountryData = action?.payload?.data
        });
        builder.addCase(getCountry.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
          // ===========State Data============== //
          builder.addCase(getState.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(getState.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.StateData = action?.payload?.data
        });
        builder.addCase(getState.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
          // ===========Role Data============== //
          builder.addCase(get_all_roles.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(get_all_roles.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.RoleData = action?.payload?.data
        });
        builder.addCase(get_all_roles.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
         // ===========Language Data============== //
         builder.addCase(get_languages.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(get_languages.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.languageData = action?.payload?.data
        });
        builder.addCase(get_languages.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
    }
})
export const { setDataTableCheck,setModuleData } = common?.actions;
export default common?.reducer;