
// Define an array of month names
let monthNames = [
  "January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];
let dayNames = [
  "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"
];
let ShortDayNames = [
  "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"
];

const getCurrentDateTime = () => {
  const now = new Date();
  const day = String(now.getDate()).padStart(2, '0');
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const year = now.getFullYear();
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}`;
};

export const formatAMPM = (date) => {
  if (date) {
    const myArray = date.split(":");
    let hours = parseInt(myArray[0]);
    const minutes = myArray[1];
    const ampm = hours >= 12 ? ' PM' : ' AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    return `${hours}:${minutes}${ampm}`;
  }
};
export const monthDayYearFormat = (inputDate, isTableFormat = false) => {
  // If not in table format, split and reverse the date
  let date = isTableFormat ? inputDate : inputDate.split('-').reverse().join('-');
  
  // Convert the input date to a Date object
  let dateObj = new Date(date);

  // Ensure the month, day, and year are correctly parsed
  let month = parseInt(dateObj.getMonth(), 10) + 1;
  let day = parseInt(dateObj.getDate(), 10);
  let year = parseInt(dateObj.getFullYear(), 10);

  // Format the date as "MM-DD-YYYY"
  let formattedDate = `${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}-${year}`;
  
  return formattedDate;
}

export const dateFormateOpenMAR = (inputDate) => {
  // Convert the input date to a Date object
  let dateObj = new Date(inputDate);
  // Get the month name and year
  let month = monthNames[dateObj.getMonth()];
  let year = dateObj.getFullYear();
  // Format the date as "Month, Year"
  let formattedDate = `${month}, ${year}`;
  return formattedDate
}
export const dayDateFormateOpenMAR = (inputDate) => {
  // Convert the input date to a Date object
  let dateObj = new Date(inputDate);
  let dayName = dayNames[dateObj.getDay()];
  let month = monthNames[dateObj.getMonth()];
  let day = dateObj.getDate();

  // Format the date as "Day, Month Day"
  let formattedDate = `${dayName}, ${month} ${day}`;

  return formattedDate;
}
export const monthDayFormateOpenMAR = (inputDate) => {  
  let date = inputDate.split('-').reverse().join('-')  
  // Convert the input date to a Date object
  let dateObj = new Date(date);
  let dayName = ShortDayNames[dateObj.getDay()];
  let day = dateObj.getDate();

  // Format the date as "Day, Month Day"
  let formattedDate = `${dayName} ${day}`;

  return formattedDate;
}

export const calculateTimeDifference = (givenDateTime, isDueCheck = false) => {  
  let splitDate = givenDateTime.split(' ');
  let reverseDate = splitDate[0].split('-').reverse().join('-');
  let dateAndTime = `${reverseDate} ${splitDate[1]}`;

  // Create a Date object from the given date and time
  const givenDate = new Date(dateAndTime.replace(' ', 'T')); // Convert to ISO format

  // Get the current date and time as a Date object
  const currentDate = getCurrentDateTime().replace(' ', 'T');
  const currentDate2 = new Date(currentDate);

  // Check if the dates are valid
  if (isNaN(givenDate.getTime()) || isNaN(currentDate2.getTime())) {
    return 'Invalid date provided';
  }

  // Calculate the difference in milliseconds
  const differenceInMilliseconds = isDueCheck ? (currentDate2 - givenDate) : (givenDate - currentDate2);

  // Convert milliseconds to total hours
  const totalHours = differenceInMilliseconds / (1000 * 60 * 60);

  // Calculate days, hours, and remaining minutes
  const days = Math.floor(totalHours / 24);
  const hours = Math.floor(totalHours % 24);
  const minutes = Math.floor((differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));

  // Output the difference
  if (isDueCheck) {
    if (days > 0) {
      return `Due ${days} day${days > 1 ? 's' : ''} ago`;
    } else if (hours > 0) {
      return `Due ${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else {
      return `Due ${Math.abs(minutes)} minute${Math.abs(minutes) !== 1 ? 's' : ''} ago`;
    }
  } else {
    if (days > 0) {
      return `${days} day${days > 1 ? 's' : ''}`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? 's' : ''}`;
    } else {
      return `${Math.abs(minutes)} minute${Math.abs(minutes) !== 1 ? 's' : ''}`;
    }
  }
};
