import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getCountry, getState } from "../../../redux/services/common";
import { useNavigate } from "react-router-dom";
import { Add_Home, CheckEmailPhone_home, Activate_members } from "../../../redux/services/AuthHomes";
import { ActiveUsers, get_all_users } from "../../../redux/services/AuthUser";
import defaultImg from '../../../Images/defaultImg.png';
import { setDataTableCheck } from "../../../redux/features/commonSlice";
import TimeZoneModal from './TimeZoneModal'
import Select from "react-select";
export const AddHome = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { register, handleSubmit, getValues, setValue, watch, trigger, control, clearErrors, formState: { errors }, reset, } = useForm({ mode: "onChange", });
  const ownerInfo = useSelector((state) => state?.owner?.ownerInfo);
  const CountryData = useSelector((state) => state?.common?.CountryData);
  const stateData = useSelector((state) => state?.common?.StateData);
  const userList = useSelector((state) => state?.AuthUsers?.AllActiveUsers);
  const MemberList = useSelector((state) => state?.AuthHomes?.ActivateMembers);
  const [activeTab, setActiveTab] = useState("home");
  const [state, setState] = useState("");
  const [countryId, setCountryID] = useState("");
  const [notification, setNotification] = useState(false);
  const [timezones, setTimezones] = useState([])
  const [selectedTimezone, setSelectedTimezone] = useState('')
  const [timezoneModal, setTimezoneModal] = useState(false)
  const [showError, setShowError] = useState({
    country: false,
    user: false,
    phone: '',
    email: '',
    home_name: ''
  });
  const [checkStepOne, setCheckStepOne] = useState(false)
  const [checkStepTwo, setCheckStepTwo] = useState(false)
  const [checkStepThree, setCheckStepThree] = useState(false)
  const [checkStepFour, setCheckStepFour] = useState(false)
  const [checkStepFive, setCheckStepFive] = useState(false)
  // const handleTabClick = (tab) => {
  //   setActiveTab(tab);
  // };
  const search = {
    role: '',
    status: '',
    page: 1,
    listData: 5,
    home: '',
    searchBar: ''
  };
  const handleNextClick = async () => {
    let valid = false;
    if (activeTab === "home") {
      if (countryId.length == 0) {
        setShowError({ country: true })
      }
      valid = await trigger(["home_name", "license_number", "cost_center_number", "country_id", "emergency_contact", "lic_issue_date", "lic_expiry_date", "lic_notification", "max_capacity", "is_active",]);
      const formData = {
        'email': getValues('email'),
        'phone_no': getValues('phone_no'),
        'home_name': getValues('home_name')
      }
      let checkEmailPhoneExist;
      if (valid) {
        checkEmailPhoneExist = await dispatch(CheckEmailPhone_home(formData))
        if (checkEmailPhoneExist?.payload?.status === false) {
          setShowError({
            email: checkEmailPhoneExist?.payload?.message.email,
            phone: checkEmailPhoneExist?.payload?.message.phone,
            home_name: checkEmailPhoneExist?.payload?.message.home
          });

        } else {

          setActiveTab("assignUser");
        }
      }
    } else if (activeTab === "assignUser") {
      setActiveTab("assignMember");
    }
  };

  const handleBackClick = () => {
    if (activeTab === "assignMember") {
      setActiveTab("assignUser");
    } else if (activeTab === "assignUser") {
      setActiveTab("home");
    }
  };
  const onSubmit = async (data) => {
    const form = new FormData();
    form.append("home_name", getValues("home_name"));
    form.append("license_number", getValues("license_number"));
    form.append("time_zone", getValues('time_zone'));
    form.append("cost_center_number", getValues("cost_center_number"));
    form.append("address1", getValues("address1"));
    form.append("address2", getValues("address2"));
    form.append("country_id", getValues("country_id"));
    form.append("state_id", getValues("state_id"));
    form.append("city", getValues("city"));
    form.append("zip_code", getValues("zip_code"));
    form.append("phone_no", getValues("phone_no"));
    form.append("emergency_contact", getValues("emergency_contact"));
    form.append("email", getValues("email"));
    form.append("fax", getValues("fax") || '');
    form.append("lic_issue_date", getValues("lic_issue_date"));
    form.append("lic_expiry_date", getValues("lic_expiry_date"))
    form.append("lic_notification", notification);
    form.append("max_capacity", getValues("max_capacity"));
    form.append("is_active", getValues("is_active"));
    form.append("userAssign[]", selectedSubOptions?.length > 0 ? selectedSubOptions?.value : []);
    form.append("memberAssign[]", selectedMemberOptions?.length > 0 ? selectedMemberOptions?.value : []);
    try {
      const response = await dispatch(Add_Home(form));
      if (response?.payload?.status === true) {
        dispatch(setDataTableCheck({ homeData: 1 }));
        dispatch(get_all_users(search));
        navigate('/auth/homes')
      }
    } catch (error) {
      console.error("Error during add home:", error);
    }
  };

  const handleStateChange = (e) => {
    setState(e);
    setValue("city", "");
  };
  const handleCountryChange = (e) => {
    setCountryID(e.target.value)
    setValue("state_id", "");
    e.target.value ? setShowError({ country: false }) : setShowError({ country: true });
    setValue("city", "");
    // setValue("time_zone")
    setState("");
    let time = CountryData && CountryData?.filter((ele) => ele.id === e.target.value)
    setTimezones(JSON.parse(time[0].timezones))
    let zone = JSON.parse(time[0].timezones)
    setSelectedTimezone(`${zone[0]?.zoneName} (${zone[0]?.gmtOffsetName})`)
    setValue('time_zone',`${zone[0]?.zoneName} (${zone[0]?.gmtOffsetName})`)
  };
  useEffect(() => {
    dispatch(getCountry());
    if (ownerInfo?.organization?.country_id || countryId) {
      dispatch(getState(countryId));
    }
  }, [countryId]);

  const onClose = () => {
    navigate("/auth/homes");
  };
  useEffect(() => {
    dispatch(ActiveUsers());
    dispatch(Activate_members());
  }, [dispatch]);

  const errorFunction = () => {
    console.log('hereeeee');
    
    let errorOne = !errors?.home_name && !errors?.cost_center_number
    let errorTwo = !errors?.country_id
    let errorThree =!errors?.emergency_contact
    let errorFour =!errors?.license_number && !errors?.lic_issue_date && !errors?.lic_expiry_date
    let errorFive =!errors?.is_active && !errors?.max_capacity
    
    setCheckStepOne(!errorOne)
    setCheckStepTwo(!errorTwo)
    setCheckStepThree(!errorThree)
    setCheckStepFour(!errorFour)
    setCheckStepFive(!errorFive)
}
useEffect(() => {
    errorFunction()
}, [errors])

  const MemberListData = MemberList?.map((item) => ({
    value: item?.id,
    label: (
      <span>{item?.first_name} {item?.last_name}</span>
    )
  }));
  const userListData = userList?.map((item) => ({
    value: item?.id,
    label: (
      <div className="add-img-sec">
        <img src={item?.profile_img_url ? item?.profile_img_url : defaultImg} />
        <span>{`${item?.first_name} ${item?.last_name}`}</span>
      </div>
    )
  }));
  //multi select user
  const [selectedSubOptions, setSelectedSubOptions] = useState([]);
  const handleUserChange = (selectedSubOptions) => {
    setSelectedSubOptions(selectedSubOptions);
  };
  // Remove a selected option
  const handleRemoveOption = (optionToRemove) => {
    if (optionToRemove && optionToRemove.value) {
      setSelectedSubOptions(selectedSubOptions.filter(option => option.value !== optionToRemove.value));
    }
  };
  //handle member
  const [selectedMemberOptions, setSelectedMemberOption] = useState([]);
  const handleMemberChange = (selectedSubOptions) => {
    setSelectedMemberOption(selectedSubOptions);
  };

  // Remove a selected option
  const handleRemoveMember = (optionToRemove) => {
    if (optionToRemove && optionToRemove.value) {
      setSelectedMemberOption(selectedSubOptions.filter(option => option.value !== optionToRemove.value));
    }
  };

  const handleTimeZone = () => {
    setTimezoneModal(!timezoneModal)
  }    

  const sortedCountryData = CountryData ? 
    [CountryData.find(elem => elem.iso2 === "US"), 
    ...CountryData.filter(elem => elem.iso2 !== "US")] 
    : [];

  return (
    <>
      <section className="step-home-sec container  ">
        <div className="sap-card-header progress-bar-sec">
          <div className="steps-progress">
            <h5 className="sap-card-title">
              STEP {(activeTab == "home" && 1) || (activeTab == "assignUser" && 2) || (activeTab == "assignMember" && 3)}  OF  3
            </h5>
            <div className="progress">
              <div className="progress-bar" role="progressbar" style={{ width: (activeTab == "home" && "30%") || (activeTab == "assignUser" && "60%") || (activeTab == "assignMember" && "100%"), }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" >25%</div>
            </div>
          </div>
          <button type="button" className="btn-close" aria-label="Close" onClick={() => onClose()} ></button>
        </div>
        <div className="pills-steps">
          <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
            <button className={`nav-link ${activeTab === "home" ? "active" : ""}`}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z" fill="white" />
                <path d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z" stroke="#98A2B3" stroke-width="2" />
                <path d="M17.3337 8L10.0003 15.3333L6.66699 12" stroke="#98A2B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              Home details
            </button>
            <button className={`nav-link ${activeTab === "assignUser" ? "active" : ""}`}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z" fill="white" />
                <path d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z" stroke="#98A2B3" stroke-width="2" />
                <path d="M17.3337 8L10.0003 15.3333L6.66699 12" stroke="#98A2B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg> Assign home to user(s)
            </button>
            <button className={`nav-link ${activeTab === "assignMember" ? "active" : ""}`}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z" fill="white" />
                <path d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z" stroke="#98A2B3" stroke-width="2" />
                <path d="M17.3337 8L10.0003 15.3333L6.66699 12" stroke="#98A2B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              Assign home to member(s)
            </button>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="tab-content" id="v-pills-tabContent">
              {activeTab === "home" && (
                <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" >
                  <div className="alert alert-light sap-alert-heading">
                    <div className="hd-subtitle font-16">
                      Fill in the basic details of a group home. Fields marked
                      with * are compulsory, and as such, can’t be left empty.
                    </div>
                  </div>
                  <div className="accordion accordion-flush sap-accor-form">
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button className={`accordion-button ${!checkStepOne ? 'collapsed' : ''}`} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded={`${checkStepOne ? 'true' : 'false'}`} aria-controls="flush-collapseOne">
                          <div className="saf-title-head">
                            <div className="saf-left">
                              <div className="saf-icon">
                                <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M0 8C0 3.58172 3.58172 0 8 0H36C40.4183 0 44 3.58172 44 8V36C44 40.4183 40.4183 44 36 44H8C3.58172 44 0 40.4183 0 36V8Z" fill="#EFF4FF" />
                                  <path d="M31 30.9998H23M12.5 31.4998L18.0493 29.3655C18.4042 29.229 18.5817 29.1607 18.7477 29.0716C18.8952 28.9924 19.0358 28.901 19.168 28.7984C19.3169 28.6829 19.4514 28.5484 19.7203 28.2795L31 16.9998C32.1046 15.8952 32.1046 14.1044 31 12.9998C29.8955 11.8952 28.1046 11.8952 27 12.9998L15.7203 24.2795C15.4514 24.5484 15.3169 24.6829 15.2014 24.8318C15.0988 24.964 15.0074 25.1046 14.9282 25.2521C14.8391 25.4181 14.7708 25.5956 14.6343 25.9506L12.5 31.4998ZM12.5 31.4998L14.5581 26.1488C14.7054 25.7659 14.779 25.5744 14.9053 25.4867C15.0157 25.4101 15.1523 25.3811 15.2843 25.4063C15.4353 25.4351 15.5804 25.5802 15.8705 25.8703L18.1296 28.1294C18.4197 28.4195 18.5647 28.5645 18.5936 28.7155C18.6188 28.8475 18.5898 28.9841 18.5131 29.0945C18.4254 29.2208 18.234 29.2944 17.8511 29.4417L12.5 31.4998Z" stroke="#2342BF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                              </div>
                              <div className="font-18">Basic information</div>
                            </div>
                            <div className="saf-right">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z" fill="#D3F8DF"></path>
                                <path d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z" stroke="#099250" stroke-width="2" ></path>
                                <path d="M17.3333 8L9.99996 15.3333L6.66663 12" stroke="#099250" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                              </svg>
                            </div>
                          </div>
                        </button>
                      </h2>
                      <div id="flush-collapseOne" className={`accordion-collapse collapse ${checkStepOne ? 'show' : ''}`} data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          <div action="" className="row">
                            <div className="col-md-6 mb-3">
                              <label for="" className="form-label">Home name* </label>
                              <input type="input" className="form-control" id="" placeholder="Home name" {...register("home_name", { required: "true" })} maxLength={20} />
                              {errors.home_name && errors.home_name.type === "required" && (<span className="error">Home name is required!</span>)}
                              <p className='error'>{showError?.home_name}</p>
                            </div>

                            <div className="col-md-6 mb-3">
                              <label for="" className="form-label"> Site code*</label>
                              <input type="input" className="form-control" id="" placeholder="Site code" {...register("cost_center_number", { required: "true", pattern: /^[A-Za-z0-9]+$/, })} maxLength={15} onKeyPress={(event) => {
                                if (!/[A-Za-z0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }} />
                              {errors.cost_center_number && errors.cost_center_number.type === "required" && (<span className="error">Site code is required!</span>)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button className={`accordion-button ${!checkStepTwo ? 'collapsed' : ''}`} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded={`${checkStepTwo ? 'true' : 'false'}`} aria-controls="flush-collapseTwo" >
                          <div className="saf-title-head">
                            <div className="saf-left">
                              <div className="saf-icon">
                                <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                  <path d="M0 8C0 3.58172 3.58172 0 8 0H36C40.4183 0 44 3.58172 44 8V36C44 40.4183 40.4183 44 36 44H8C3.58172 44 0 40.4183 0 36V8Z" fill="#EFF4FF" />
                                  <path d="M30 22C30 26.4183 26.4183 30 22 30M30 22C30 17.5817 26.4183 14 22 14M30 22H32M22 30C17.5817 30 14 26.4183 14 22M22 30V32M14 22C14 17.5817 17.5817 14 22 14M14 22H12M22 14V12M25 22C25 23.6569 23.6569 25 22 25C20.3431 25 19 23.6569 19 22C19 20.3431 20.3431 19 22 19C23.6569 19 25 20.3431 25 22Z" stroke="#2342BF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                              </div>
                              <div className="font-18">Location</div>
                            </div>
                            <div className="saf-right">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z" fill="#D3F8DF">
                                </path><path d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z" stroke="#099250" stroke-width="2"></path>
                                <path d="M17.3333 8L9.99996 15.3333L6.66663 12" stroke="#099250" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                              </svg>
                            </div>
                          </div>
                        </button>
                      </h2>
                      <div id="flush-collapseTwo" className={`accordion-collapse collapse ${checkStepTwo ? 'show' : ''}`} data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          <div className="row">
                            <div className="col-md-6 mb-3">
                              <label for="" className="form-label">Address</label>
                              <input type="input" className="form-control" id="" placeholder="Address" {...register("address1")} maxLength={50} />
                              {/* {errors.address1 && errors.address1.type === "required" && (<span className="error">Address is required!</span>)} */}
                            </div>
                            <div className="col-md-6 mb-3">
                              <label for="" className="form-label">Address2</label>
                              <input type="text" className="form-control" id="2" placeholder="Address" {...register("address2")} maxLength={50} />
                            </div>

                            <div className="col-md-6 mb-3">
                              <label for="" className="form-label">Country*</label>
                              <select className="form-select" aria-label="Default select example" {...register("country_id", { required: true })} onChange={(e) => (handleCountryChange(e))} name="country_id" autocomplete="off">
                                <option value="">Select country</option>
                                {sortedCountryData && sortedCountryData.map((elem) => {                                  
                                  return (<option key={elem?.id} value={elem?.id}> {elem?.country}</option>
                                  );
                                })}
                              </select>
                              {showError.country ? errors.country_id && errors.country_id.type === "required" && (<span className="error">Counrty is required</span>) : ""}
                            </div>
                            <div className="col-md-6 mb-3">
                              <label for="" className="form-label">State</label>
                              <select className="form-select" aria-label="Default select example" name="state_id" {...register("state_id")} onChange={(e) => handleStateChange(e.target.value)} >
                                <option value="">Select state</option>
                                {stateData && stateData?.map((elem) => {
                                  return (
                                    <option value={elem?.id}>{elem?.address_state}</option>
                                  );
                                })}
                              </select>
                              {/* {state === "" ? errors.state_id && errors.state_id.type === "required" && (<span className="error">State is required!   </span>) : ""} */}
                            </div>
                            <div className="col-md-6 mb-3">
                              <label for="" className="form-label">City</label>
                              <input type="input" className="form-control" id="" placeholder="City" {...register("city")}
                              />
                              {/* {errors.city && errors.city.type === "required" && (<span className="error"> City is required!</span>)} */}
                            </div>
                            <div className="col-md-6 mb-3">
                              <label for="" className="form-label">Zip code</label>
                              <input type="text" className="form-control" id="" onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} maxLength={5} placeholder="Zip code"
                                {...register("zip_code", { pattern: { value: /^\d{5}$/, message: "ZIP code must be exactly 5 digits", } })} />
                              {/* {errors.zip_code && errors.zip_code.type === "required" && (<span className="error">Zipcode is required!</span>)} */}
                              {errors.zip_code && errors.zip_code.type === "pattern" && (<span className="error">{errors?.zip_code?.message}</span>)}
                            </div>
                            <div className="col-md-8 mb-3">
                              <label htmlFor="timezone" className="form-label">Timezone Location</label>
                              <input type="input" className="form-control" id="" value={selectedTimezone} placeholder="Timezone" {...register("time_zone")} readOnly/>
                              {/* {errors.time_zone && errors.time_zone.type === "required" && (<span className="error">Timezone is required!</span>)} */}
                            </div>
                            <div className="col-md-4 login-btn">
                              {timezones && timezones.length > 1 &&
                              <>
                              <button type="button" className="btn btn-primary timezone" onClick={handleTimeZone} >Change Timezone</button>
                              </>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button className={`accordion-button ${!checkStepThree ? 'collapsed' : ''}`} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded={`${checkStepThree ? 'true' : 'false'}`} aria-controls="flush-collapseThree" >
                          <div className="saf-title-head">
                            <div className="saf-left">
                              <div className="saf-icon">
                                <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                  <path d="M0 8C0 3.58172 3.58172 0 8 0H36C40.4183 0 44 3.58172 44 8V36C44 40.4183 40.4183 44 36 44H8C3.58172 44 0 40.4183 0 36V8Z" fill="#EFF4FF" />
                                  <path d="M14.8586 16C16.6736 14.1486 19.2027 13 22.0001 13C24.7975 13 27.3266 14.1486 29.1415 16M26.4723 19C25.3736 17.7725 23.777 17 22 17C20.223 17 18.6265 17.7725 17.5278 19M22 27C23.5105 27 24.9608 27.2576 26.3094 27.7313C26.3542 27.747 26.3767 27.7549 26.412 27.7705C26.7326 27.9118 26.9788 28.2591 27.0058 28.6084C27.0088 28.647 27.0088 28.6811 27.0088 28.7494C27.0088 28.9821 27.0088 29.0985 27.0185 29.1965C27.1122 30.1457 27.8631 30.8966 28.8123 30.9903C28.9103 31 29.0267 31 29.2594 31H29.5044C29.965 31 30.1952 31 30.3868 30.9622C31.1829 30.8053 31.8053 30.1829 31.9622 29.3868C32 29.1952 32 28.965 32 28.5044V28.3062C32 27.831 32 27.5933 31.9493 27.3209C31.8358 26.7119 31.3933 25.9583 30.9166 25.5624C30.7035 25.3854 30.5589 25.3048 30.2698 25.1435C27.822 23.7781 25.0019 23 22 23C18.9981 23 16.178 23.7781 13.7302 25.1435C13.4411 25.3048 13.2965 25.3854 13.0834 25.5624C12.6067 25.9583 12.1642 26.7119 12.0507 27.3209C12 27.5933 12 27.831 12 28.3062V28.5044C12 28.965 12 29.1952 12.0378 29.3868C12.1947 30.1829 12.8171 30.8053 13.6132 30.9622C13.8048 31 14.035 31 14.4956 31H14.7406C14.9733 31 15.0897 31 15.1877 30.9903C16.1369 30.8966 16.8878 30.1457 16.9815 29.1965C16.9912 29.0985 16.9912 28.9821 16.9912 28.7494C16.9912 28.6811 16.9912 28.647 16.9942 28.6084C17.0212 28.2591 17.2674 27.9118 17.588 27.7705C17.6233 27.7549 17.6458 27.747 17.6906 27.7313C19.0392 27.2576 20.4895 27 22 27Z" stroke="#2342BF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                              </div>
                              <div className="font-18">Contact Details</div>
                            </div>
                            <div className="saf-right">
                              <svg width="24" height="24" viewBox="0 0 24 24  fill=" none xmlns="http://www.w3.org/2000/svg" >
                                <path d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z" fill="#D3F8DF"></path>
                                <path d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z" stroke="#099250" stroke-width="2"></path>
                                <path d="M17.3333 8L9.99996 15.3333L6.66663 12" stroke="#099250" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" ></path>
                              </svg>
                            </div>
                          </div>
                        </button>
                      </h2>
                      <div id="flush-collapseThree" className={`accordion-collapse collapse ${checkStepThree ? 'show' : ''}`} data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          <div className="row">
                            <div className="col-md-12 mb-3">
                              <label for="" className="form-label"> Phone</label>
                              <input type="input" className="form-control" id="" maxLength={15} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} placeholder=" Phone number" {...register("phone_no", {pattern: { value: /^\d{10,15}$/, message: "Phone number must be 10 to 15 digits" } })} />
                              {/* {errors.phone_no && errors.phone_no.type === "required" && (<span className="error">Phone number is required</span>)} */}
                              {errors.phone_no && errors.phone_no.type === "pattern" && (<span className="error">{errors?.phone_no?.message}</span>)}
                              {/* <p className='error'>{showError?.phone}</p> */}
                            </div>
                            <div className="col-md-12 mb-3">
                              <label for="" className="form-label"> Email address</label>
                              <input type="input" className="form-control" id="" placeholder="Email address" {...register("email", {pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: "Invalid email address", }, })} maxLength={50} />
                              {/* {errors.email && errors.email.type === "required" && (<span className="error">Email is required!</span>)} */}
                              {errors.email && errors.email.type === "pattern" && (<span className="error">{errors?.email?.message}</span>)}
                              {/* <p className='error'>{showError?.email}</p> */}
                            </div>
                            <div className="col-md-12 mb-3">
                              <label for="" className="form-label">Fax</label>
                              <input type="input" className="form-control" id="" placeholder="Fax number" maxLength={20} {...register("fax")} />
                              {/* {errors.fax && errors.fax.type === "required" && (
                                <span className="error">Fax is required!</span>
                              )} */}
                            </div>
                            <div className="col-md-12 mb-3">
                              <label for="" className="form-label">Emergency Contact*</label>
                              <input type="input" className="form-control" id="" maxLength={15} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} placeholder=" Emergency Contact" {...register("emergency_contact", { required: true, pattern: { value: /^\d{10,15}$/, message: "Emergency Contact must be 10 to 15 digits" } })} />
                              {errors.emergency_contact && errors.emergency_contact.type === "required" && (<span className="error">Emergency Contact is required</span>)}
                              {errors.emergency_contact && errors.emergency_contact.type === "pattern" && (<span className="error">{errors?.emergency_contact?.message}</span>)}
                              <p className='error'>{showError?.emergency_contact}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button className={`accordion-button ${!checkStepFour ? 'collapsed' : ''}`} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded={`${checkStepFour ? 'true' : 'false'}`} aria-controls="flush-collapseFour">
                          <div className="saf-title-head">
                            <div className="saf-left">
                              <div className="saf-icon">
                                <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                  <path d="M0 8C0 3.58172 3.58172 0 8 0H36C40.4183 0 44 3.58172 44 8V36C44 40.4183 40.4183 44 36 44H8C3.58172 44 0 40.4183 0 36V8Z" fill="#EFF4FF" />
                                  <path d="M14 18H30M14 26H30M18 13V31M26 13V31" stroke="#2342BF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                              </div>
                              <div className="font-18">License Information</div>
                            </div>
                            <div className="saf-right">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z" fill="#D3F8DF"></path>
                                <path d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z" stroke="#099250" stroke-width="2" ></path>
                                <path d="M17.3333 8L9.99996 15.3333L6.66663 12" stroke="#099250" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" ></path>
                              </svg>
                            </div>
                          </div>
                        </button>
                      </h2>
                      <div id="flush-collapseFour" className={`accordion-collapse collapse ${checkStepFour ? 'show' : ''}`} data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          <div action="" className="row">
                            <div className="col-md-12 mb-3">
                              <label for="" className="form-label"> License Number* </label>
                              <input
                                type="input"
                                className="form-control"
                                id="license_number"
                                placeholder=" License Number"
                                {...register("license_number", {
                                  required: true,
                                  pattern: /^[A-Za-z0-9]+$/, // Only allow alphanumeric characters
                                })}
                                maxLength={20}
                                onKeyPress={(event) => {
                                  if (!/[A-Za-z0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                              {errors.license_number && errors.license_number.type === "required" && (<span className="error"> License number is required!</span>)}
                            </div>
                            <div className="col-md-6 mb-3">
                              <label for="" className="form-label">Issue Date* </label>
                              <input type="date" className="form-control" id="" placeholder="Issue Date" {...register("lic_issue_date", { required: "true", })}
                                // max={(new Date(Date.now() - 86400000)).toISOString().split('T')[0]}
                                onKeyDown={(e) => e.preventDefault()} />
                              {errors.lic_issue_date && errors.lic_issue_date.type === "required" && (<span className="error"> Issue Date is required!</span>)}
                            </div>
                            <div className="col-md-6 mb-3">
                              <label for="" className="form-label"> Expiration Date*</label>
                              <input type="date" className="form-control" id="" placeholder="Expiration Date" {...register("lic_expiry_date", { required: "true", })}
                                //  min={new Date().toISOString().split('T')[0]} max="2099-12-31"
                                onKeyDown={(e) => e.preventDefault()} />
                              {errors.lic_expiry_date && errors.lic_expiry_date.type === "required" && (<span className="error"> Expiration Date is required!   </span>)}
                            </div>
                            <div className="col-md-12 mb-3">
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" value='' id="flexCheckDefault"
                                  // {...register("lic_notification")} 
                                  onChange={(e) => setNotification(e.target.checked)} />
                                <label className="form-check-label" for="flexCheckDefault" > By checking this box you choose to be notified 90 days before the home license expiration date </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button className={`accordion-button ${!checkStepFive ? 'collapsed' : ''}`} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded={`${checkStepFive ? 'true' : 'false'}`} aria-controls="flush-collapseFive">
                          <div className="saf-title-head">
                            <div className="saf-left">
                              <div className="saf-icon">
                                <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M0 8C0 3.58172 3.58172 0 8 0H36C40.4183 0 44 3.58172 44 8V36C44 40.4183 40.4183 44 36 44H8C3.58172 44 0 40.4183 0 36V8Z" fill="#EFF4FF" />
                                  <path d="M14 18H30M14 26H30M18 13V31M26 13V31" stroke="#2342BF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                              </div>
                              <div className="font-18">Others</div>
                            </div>
                            <div className="saf-right">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z" fill="#D3F8DF"></path>
                                <path d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z" stroke="#099250" stroke-width="2"></path>
                                <path d="M17.3333 8L9.99996 15.3333L6.66663 12" stroke="#099250" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" ></path>
                              </svg>
                            </div>
                          </div>
                        </button>
                      </h2>
                      <div id="flush-collapseFive" className={`accordion-collapse collapse ${checkStepFive ? 'show' : ''}`} data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          <div className="row">
                            <div className="col-md-6 mb-3">
                              <label for="" className="form-label"> Status*</label>
                              <select className="form-select" aria-label="Default select example" {...register("is_active", { required: "true" })}>
                                <option value="">Select Status</option>
                                <option value="true">Active</option>
                                <option value="false">InActive</option>
                              </select>
                              {errors.is_active && errors.is_active.type === "required" && (<span className="error">Status is required!</span>)}
                            </div>
                            <div className="col-md-6 mb-3">
                              <label for="" className="form-label">Home Max capacity* </label>
                              <input type="input" className="form-control" id="" placeholder=" Home Max capacity" onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} {...register("max_capacity", { required: "true" })} maxLength={3}
                                onKeyPress={(event) => {
                                  if (event.key === '0' && (event.target.value === '' || event.target.value === '0')) {
                                    event.preventDefault();
                                  } else if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }} />
                              {errors.max_capacity && errors.max_capacity.type === "required" && (<span className="error">maxCapacity is required!</span>)}
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="col-md-12 login-btn">
                        <button type="button" className="btn btn-primary" onClick={handleNextClick} >Next</button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {activeTab === "assignUser" && (
                <div className="tab-pane fade show active" id="v-pills-profile" role="tabpanel" >
                  <div className="alert alert-light sap-alert-heading">
                    <div className="hd-subtitle font-16">
                      Search and add as many users that need to be assigned to this home. </div>
                  </div>
                  <div className="col-md-12 mb-3 mt-5 ">
                    <div className="form-group">
                      <Controller
                        name="assignUser"
                        control={control}
                        defaultValue={[]}
                        render={({ field }) => (
                          <Select
                            options={userListData}
                            isMulti
                            {...field}
                            value={selectedSubOptions}
                            onChange={(option) => handleUserChange(option)}
                            placeholder="Select user"
                            closeMenuOnSelect={true}
                            components={{
                              MultiValue: () => null,
                              MultiValueLabel: () => null,
                              MultiValueRemove: () => null,
                              IndicatorSeparator: () => null,
                            }}
                          />
                        )}
                      />
                      {selectedSubOptions.length > 0 && (
                        <div className="col-md-12 mb-5">
                          <div className="home-user-sec">
                            {selectedSubOptions.map(option => (
                              <button type="button" className="btn btn-light home-users" key={option.value}>
                                {option.label}
                                <span onClick={() => handleRemoveOption(option)}>
                                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                    <path d="M15 5L5 15M5 5L15 15" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"></path>
                                  </svg>
                                </span>
                              </button>
                            ))}
                          </div>
                        </div>
                      )}
                      {/* {showError?.user &&
                        (<span className="error">Assign home to user required!</span>)} */}
                    </div>
                  </div>
                  <div className="col-md-12 login-btn">
                    <div className="three-btn-sec">
                      <div className="two-btn-sec ">
                        <button type="button" className="btn btn-light" onClick={handleNextClick} > Skip</button>
                      </div>
                      <div className="two-btn-sec ">
                        <button type="button" className="btn btn-light me-2" onClick={handleBackClick}>
                          <svg width="16" height="14" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.91683 5.00016H2.0835M2.0835 5.00016L5.00016 7.91683M2.0835 5.00016L5.00016 2.0835" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                          Go Back
                        </button>
                        <button type="button" className="btn btn-primary" onClick={handleNextClick} >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {activeTab === "assignMember" && (
                <div className="tab-pane fade show active" id="v-pills-messages" role="tabpanel" >
                  <div className="alert alert-light sap-alert-heading">
                    <div className="hd-subtitle font-16">
                      Search and add as many members that need to be assigned to this home.
                    </div>
                  </div>
                  <div className="col-md-12 mb-3 mt-5 ">
                    <div className="form-group">
                      <Controller
                        name="assingMember"
                        control={control}
                        defaultValue={[]}
                        render={({ field }) => (
                          <Select
                            options={MemberListData}
                            isMulti={false}
                            {...field}
                            value={selectedMemberOptions}
                            onChange={(option) => handleMemberChange(option)}
                            placeholder="Select member"
                            closeMenuOnSelect={true}
                            components={{
                              MultiValue: () => null,
                              MultiValueLabel: () => null,
                              MultiValueRemove: () => null,
                              IndicatorSeparator: () => null,
                            }}
                          />
                        )}
                      />
                      {selectedMemberOptions.length > 0 && (
                        <div className="col-md-12 mb-5">
                          <div className="home-user-sec">
                            {selectedMemberOptions.map(option => (
                              <button type="button" className="btn btn-light home-users" key={option.value}>
                                {option.label}
                                <span onClick={() => handleRemoveMember(option)}>
                                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                    <path d="M15 5L5 15M5 5L15 15" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"></path>
                                  </svg>
                                </span>
                              </button>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* <div className="col-md-12 mb-5">
                    <div className="home-user-sec">
                      <button type="button" className="btn btn-light">
                        <img src="../assets\images\img.png" />
                        Alfred Payne
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M15 5L5 15M5 5L15 15" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" ></path>
                        </svg>
                      </button>
                      <button type="button" className="btn btn-light">
                        <img src="../assets\images\img.png" />
                        Giannis Antetokounmpo
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M15 5L5 15M5 5L15 15" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                      </button>
                      <button type="button" className="btn btn-light">
                        <img src="../assets\images\img.png" />
                        Adolf Hitler
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M15 5L5 15M5 5L15 15" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" ></path>
                        </svg>
                      </button>
                      <button type="button" className="btn btn-light">
                        <img src="../assets\images\img.png" />
                        Hiroshima Nagasaki
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" >
                          <path d="M15 5L5 15M5 5L15 15" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                      </button>
                    </div>
                  </div> */}
                  <div className="col-md-12 login-btn">
                    <div className="two-btn-sec">
                      <button type="button" className="btn btn-light me-2" onClick={handleBackClick}>
                        <svg width="16" height="14" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" >
                          <path d="M7.91683 5.00016H2.0835M2.0835 5.00016L5.00016 7.91683M2.0835 5.00016L5.00016 2.0835" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        Go Back
                      </button>
                      <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </form>
          <TimeZoneModal modalIsOpen={timezoneModal} setModalIsOpen={setTimezoneModal} timezones={timezones} setSelectedTimezone={setSelectedTimezone}/>
        </div>
      </section>
    </>
  );
};
