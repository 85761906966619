import { createSlice } from "@reduxjs/toolkit";
import { get_all_users_list_mar, get_all_member_list_mar, get_all_master_medication_list, get_all_master_units_list, get_all_master_does_form_list, get_all_master_coding_system_list, get_all_master_route_list, get_all_master_type_list, get_all_medications, Add_Medication, edit_member_medication, staff_update_checkbox, get_edit_listing_all_data, admin_update_status_comment, get_open_mar_details, export_all_medication, get_all_member_mar_list } from "../services/AuthMar";

const initialState = {
    isLoading: false,
    error: null,
    AllUserListMar: [],
    AllMemberListMar: [],
    MasterMedicationList: [],
    MasterUnitsList: [],
    MasterDoesForm: [],
    MasterCodingSystem: [],
    MasterRoute: [],
    MasterTypeList: [],
    AllMedications: [],
    AllMedicationsMember: [],
    EditMemberDetails: [],
    AllListingData: [],
    OpenMarDetails: [],
}
const AuthMar = createSlice({
    name: 'AuthMar',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {

        // ==========get_all_users_list_mar============== //
        builder.addCase(get_all_users_list_mar.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(get_all_users_list_mar.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.AllUserListMar = action?.payload?.data
        });
        builder.addCase(get_all_users_list_mar.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========get_all_member_list_mar============== //
        builder.addCase(get_all_member_list_mar.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(get_all_member_list_mar.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.AllMemberListMar = action?.payload?.data
        });
        builder.addCase(get_all_member_list_mar.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========get_all_master_medication_list============== //
        builder.addCase(get_all_master_medication_list.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(get_all_master_medication_list.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.MasterMedicationList = action?.payload?.data
        });
        builder.addCase(get_all_master_medication_list.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========get_all_master_units_list============== //
        builder.addCase(get_all_master_units_list.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(get_all_master_units_list.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.MasterUnitsList = action?.payload?.data
        });
        builder.addCase(get_all_master_units_list.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========get_all_master_does_form_list============== //
        builder.addCase(get_all_master_does_form_list.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(get_all_master_does_form_list.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.MasterDoesForm = action?.payload?.data
        });
        builder.addCase(get_all_master_does_form_list.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========get_all_master_coding_system_list============== //
        builder.addCase(get_all_master_coding_system_list.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(get_all_master_coding_system_list.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.MasterCodingSystem = action?.payload?.data
        });
        builder.addCase(get_all_master_coding_system_list.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========get_all_master_route_list============== //
        builder.addCase(get_all_master_route_list.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(get_all_master_route_list.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.MasterRoute = action?.payload?.data
        });
        builder.addCase(get_all_master_route_list.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========get_all_master_type_list============== //
        builder.addCase(get_all_master_type_list.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(get_all_master_type_list.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.MasterTypeList = action?.payload?.data
        });
        builder.addCase(get_all_master_type_list.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========get_all_medications============== //
        builder.addCase(get_all_medications.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(get_all_medications.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.AllMedications = action?.payload?.data
        });
        builder.addCase(get_all_medications.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });

        // ==========Add medication ============== //
        builder.addCase(Add_Medication.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Add_Medication.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Add_Medication.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========edit_member_medication============== //
        builder.addCase(edit_member_medication.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(edit_member_medication.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.EditMemberDetails = action?.payload?.data
        });
        builder.addCase(edit_member_medication.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========staff_update_checkbox ============== //
        builder.addCase(staff_update_checkbox.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(staff_update_checkbox.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(staff_update_checkbox.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========get_edit_listing_all_data============== //
        builder.addCase(get_edit_listing_all_data.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(get_edit_listing_all_data.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.AllListingData = action?.payload?.data
        });
        builder.addCase(get_edit_listing_all_data.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========admin_update_status_comment ============== //
        builder.addCase(admin_update_status_comment.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(admin_update_status_comment.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(admin_update_status_comment.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========get_open_mar_details============== //
        builder.addCase(get_open_mar_details.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(get_open_mar_details.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.OpenMarDetails = action?.payload?.data
        });
        builder.addCase(get_open_mar_details.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========export_all_medication============== //
        builder.addCase(export_all_medication.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(export_all_medication.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(export_all_medication.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========get_all_member_mar_list============== //
        builder.addCase(get_all_member_mar_list.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(get_all_member_mar_list.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.AllMedicationsMember = action?.payload?.data
        });
        builder.addCase(get_all_member_mar_list.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
    }
});

export default AuthMar?.reducer;