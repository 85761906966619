import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { customAlert } from "../../utils/alert";
import { HTTPURL } from "../../constant/Matcher";


//get  all-users-list-mar
export const get_all_users_list_mar = createAsyncThunk(
    "auth/all-users-list-mar",
    async (SearchData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/all-users-list-mar`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);
//get  all-member-list-mar
export const get_all_member_list_mar = createAsyncThunk(
    "auth/all-member-list-mar",
    async (SearchData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/all-member-list-mar`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);
// get all-master-medication
export const get_all_master_medication_list = createAsyncThunk(
    "/api/all-master-medication",
    async (_, thunkAPI) => {
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/all-master-medication`,
                headers: {
                    "Content-Type": "application/json",
                }
            };
            const res = await axios.request(config);
            return res.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
            return rejectWithValue(error?.response?.data);
        }
    }
);
// get all-master-units
export const get_all_master_units_list = createAsyncThunk(
    "/api/all-master-units",
    async (_, thunkAPI) => {
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/all-master-units`,
                headers: {
                    "Content-Type": "application/json",
                }
            };
            const res = await axios.request(config);
            return res.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
            return rejectWithValue(error?.response?.data);
        }
    }
);
// get all-master-does-form
export const get_all_master_does_form_list = createAsyncThunk(
    "/api/all-master-does-form",
    async (_, thunkAPI) => {
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/all-master-does-form`,
                headers: {
                    "Content-Type": "application/json",
                }
            };
            const res = await axios.request(config);
            return res.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
            return rejectWithValue(error?.response?.data);
        }
    }
);
// get all-master-coding-system
export const get_all_master_coding_system_list = createAsyncThunk(
    "/api/all-master-coding-system",
    async (_, thunkAPI) => {
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/all-master-coding-system`,
                headers: {
                    "Content-Type": "application/json",
                }
            };
            const res = await axios.request(config);
            return res.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
            return rejectWithValue(error?.response?.data);
        }
    }
);
// get all-master-route
export const get_all_master_route_list = createAsyncThunk(
    "/api/all-master-route",
    async (_, thunkAPI) => {
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/all-master-route`,
                headers: {
                    "Content-Type": "application/json",
                }
            };
            const res = await axios.request(config);
            return res.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
            return rejectWithValue(error?.response?.data);
        }
    }
);
// get all-master-type
export const get_all_master_type_list = createAsyncThunk(
    "/api/all-master-type",
    async (_, thunkAPI) => {
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/all-master-type`,
                headers: {
                    "Content-Type": "application/json",
                }
            };
            const res = await axios.request(config);
            return res.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
            return rejectWithValue(error?.response?.data);
        }
    }
);
//get  all-medications
export const get_all_medications = createAsyncThunk(
    "auth/all-medications",
    async (SearchData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/all-medications?page=${SearchData?.page}&listData=${SearchData?.listData}&approval_status=${SearchData.approval_status}&admin_status=${SearchData.admin_status}&searchQuery=${SearchData?.searchBar}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);
// add-medication
export const Add_Medication = createAsyncThunk(
    "/api/add-medication",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/add-medication `,
                headers: {
                    // "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                customAlert(res?.data?.message, 'success');
            } else {
                customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);
//get edit-member-medication
export const edit_member_medication = createAsyncThunk(
    "auth/edit-member-medication",
    async (id, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/edit-member-medication?medication_id=${id}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);
// staff-update-checkbox
export const staff_update_checkbox = createAsyncThunk(
    "/api/staff-update-checkbox",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/staff-update-checkbox `,
                headers: {
                    // "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                customAlert(res?.data?.message, 'success');
            } else {
                customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);
//get  edit-listing-all-data
export const get_edit_listing_all_data = createAsyncThunk(
    "auth/edit-listing-all-data",
    async (SearchData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/edit-listing-all-data?page=${SearchData?.page}&listData=${SearchData?.listData}&mar_detail_id=${SearchData?.mar_detail_id}&type=${SearchData?.type}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);
// admin-update-status-comment
export const admin_update_status_comment = createAsyncThunk(
    "/api/admin-update-status-comment",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/admin-update-status-comment `,
                headers: {
                    // "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                customAlert(res?.data?.message, 'success');
            } else {
                customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);
//get open-mar-details
export const get_open_mar_details = createAsyncThunk(
    "auth/open-mar-details",
    async (id, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/open-mar-details?member_id=${id}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);
//get export-all-medication
export const export_all_medication = createAsyncThunk(
    "auth/export-all-medication",
    async (SearchData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/export-all-medication`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);
//get  member-mar-list
export const get_all_member_mar_list = createAsyncThunk(
    "auth/member-mar-list",
    async (SearchData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/member-mar-list?page=${SearchData?.page}&listData=${SearchData?.listData}&approval_status=${SearchData.approval_status}&member_id=${SearchData?.member_id}&searchQuery=${SearchData?.searchBar}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);