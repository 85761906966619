import React, { useEffect, useRef } from 'react'
import HomeLineChart from './Charts/HomeLineChart';
import BarChart from './Charts/BarChart';
import LineChart from './Charts/LineChart';
import RedLineChart from './Charts/RedLineChart';
const Dashboard = () => {

  return (
    <div id="layoutSidenav_content">
      <main>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 col-lg-12 col-12">
            <div className="right-main-sec">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="#">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M6.77168 11.6663C7.14172 13.104 8.4468 14.1663 10 14.1663C11.5532 14.1663 12.8583 13.104 13.2283 11.6663M9.18141 2.30297L3.52949 6.6989C3.15168 6.99275 2.96278 7.13968 2.82669 7.32368C2.70614 7.48667 2.61633 7.67029 2.56169 7.86551C2.5 8.0859 2.5 8.32521 2.5 8.80384V14.833C2.5 15.7664 2.5 16.2331 2.68166 16.5896C2.84144 16.9032 3.09641 17.1582 3.41002 17.318C3.76654 17.4996 4.23325 17.4996 5.16667 17.4996H14.8333C15.7668 17.4996 16.2335 17.4996 16.59 17.318C16.9036 17.1582 17.1586 16.9032 17.3183 16.5896C17.5 16.2331 17.5 15.7664 17.5 14.833V8.80384C17.5 8.32521 17.5 8.0859 17.4383 7.86551C17.3837 7.67029 17.2939 7.48667 17.1733 7.32368C17.0372 7.13968 16.8483 6.99275 16.4705 6.69891L10.8186 2.30297C10.5258 2.07526 10.3794 1.9614 10.2178 1.91763C10.0752 1.87902 9.92484 1.87902 9.78221 1.91763C9.62057 1.9614 9.47418 2.07526 9.18141 2.30297Z"
                        stroke="#667085" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                  </a>
                </li>
                <li className="breadcrumb-item active">Dashboard</li>
              </ol>

              <div className="right-main-header search-sec">
                <div className="left-side-content">
                  <div className="font-30 hd-title">Dashboard</div>
                  <p className="hd-subtitle">Your homes’ summary and activities </p>
                </div>
                <div className="left-side-content searchbar">
                  <div className="form-group"><input type="text" name="" id="" className="form-control"
                    placeholder="Search" />
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M16.5 16.5L13.5834 13.5833M15.6667 8.58333C15.6667 12.4954 12.4954 15.6667 8.58333 15.6667C4.67132 15.6667 1.5 12.4954 1.5 8.58333C1.5 4.67132 4.67132 1.5 8.58333 1.5C12.4954 1.5 15.6667 4.67132 15.6667 8.58333Z"
                        stroke="#667085" stroke-width="1.66667" stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                  </div>
                </div>
              </div>
              {/* <div className="table-btn-sec">
                <div className="btn-group progress-checkbox" role="group"
                  aria-label="Basic checkbox toggle button group">
                  <input type="checkbox" className="btn-check" id="btncheck1" autocomplete="off" />
                  <label className="btn btn-outline-secondary" for="btncheck1">12 months</label>

                  <input type="checkbox" className="btn-check" id="btncheck2" autocomplete="off" />
                  <label className="btn btn-outline-secondary" for="btncheck2">30 days</label>

                  <input type="checkbox" className="btn-check" id="btncheck3" autocomplete="off" />
                  <label className="btn btn-outline-secondary" for="btncheck3">7 days</label>

                  <input type="checkbox" className="btn-check" id="btncheck4" autocomplete="off" />
                  <label className="btn btn-outline-secondary" for="btncheck4">24 Hours</label>
                </div>
                <button type="button" className="btn btn-light">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 10H15M2.5 5H17.5M7.5 15H12.5" stroke="#344054"
                      stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  Filters
                </button>
              </div>
              <div className="charts">
              <BarChart />
              </div> */}
              <div className="row">
                <div className="col-md-4 col-12">
                  <div className="graph-content">
                    <div className="graph-header">
                      <div className="font-16 hd-title">Members</div>
                      <a href="#">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M10.0003 10.8335C10.4606 10.8335 10.8337 10.4604 10.8337 10.0002C10.8337 9.53993 10.4606 9.16683 10.0003 9.16683C9.54009 9.16683 9.16699 9.53993 9.16699 10.0002C9.16699 10.4604 9.54009 10.8335 10.0003 10.8335Z"
                            stroke="#98A2B3" stroke-width="1.66667"
                            stroke-linecap="round" stroke-linejoin="round" />
                          <path
                            d="M10.0003 5.00016C10.4606 5.00016 10.8337 4.62707 10.8337 4.16683C10.8337 3.70659 10.4606 3.3335 10.0003 3.3335C9.54009 3.3335 9.16699 3.70659 9.16699 4.16683C9.16699 4.62707 9.54009 5.00016 10.0003 5.00016Z"
                            stroke="#98A2B3" stroke-width="1.66667"
                            stroke-linecap="round" stroke-linejoin="round" />
                          <path
                            d="M10.0003 16.6668C10.4606 16.6668 10.8337 16.2937 10.8337 15.8335C10.8337 15.3733 10.4606 15.0002 10.0003 15.0002C9.54009 15.0002 9.16699 15.3733 9.16699 15.8335C9.16699 16.2937 9.54009 16.6668 10.0003 16.6668Z"
                            stroke="#98A2B3" stroke-width="1.66667"
                            stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </a>
                    </div>
                    <div className="g-content-box">
                      <div className="content-box">
                        <div className="font-30 hd-title">1000</div>
                        <div className="progrss-sec">
                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M10.0003 15.8332V4.1665M10.0003 4.1665L4.16699 9.99984M10.0003 4.1665L15.8337 9.99984"
                              stroke="#17B26A" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round" />
                          </svg>
                          <p><span>15%</span> last mth</p>
                        </div>
                      </div>
                      <div className="content-box">
                      <LineChart />
                      </div>
                    </div>

                  </div>
                </div>
                <div className="col-md-4 col-12">
                  <div className="graph-content">
                    <div className="graph-header">
                      <div className="font-16 hd-title">Staff</div>
                      <a href="#">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M10.0003 10.8335C10.4606 10.8335 10.8337 10.4604 10.8337 10.0002C10.8337 9.53993 10.4606 9.16683 10.0003 9.16683C9.54009 9.16683 9.16699 9.53993 9.16699 10.0002C9.16699 10.4604 9.54009 10.8335 10.0003 10.8335Z"
                            stroke="#98A2B3" stroke-width="1.66667"
                            stroke-linecap="round" stroke-linejoin="round" />
                          <path
                            d="M10.0003 5.00016C10.4606 5.00016 10.8337 4.62707 10.8337 4.16683C10.8337 3.70659 10.4606 3.3335 10.0003 3.3335C9.54009 3.3335 9.16699 3.70659 9.16699 4.16683C9.16699 4.62707 9.54009 5.00016 10.0003 5.00016Z"
                            stroke="#98A2B3" stroke-width="1.66667"
                            stroke-linecap="round" stroke-linejoin="round" />
                          <path
                            d="M10.0003 16.6668C10.4606 16.6668 10.8337 16.2937 10.8337 15.8335C10.8337 15.3733 10.4606 15.0002 10.0003 15.0002C9.54009 15.0002 9.16699 15.3733 9.16699 15.8335C9.16699 16.2937 9.54009 16.6668 10.0003 16.6668Z"
                            stroke="#98A2B3" stroke-width="1.66667"
                            stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </a>
                    </div>
                    <div className="g-content-box">
                      <div className="content-box">
                        <div className="font-30 hd-title">57</div>
                        <div className="progrss-sec">
                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M10.0003 4.1665V15.8332M10.0003 15.8332L15.8337 9.99984M10.0003 15.8332L4.16699 9.99984"
                              stroke="#F04438" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round" />
                          </svg>
                          <p><span className="red">10%</span> last mth</p>
                        </div>
                      </div>
                      <div className="content-box">
                        <RedLineChart />

                      </div>
                    </div>

                  </div>
                </div>
                <div className="col-md-4 col-12">
                  <div className="graph-content">
                    <div className="graph-header">
                      <div className="font-16 hd-title">Homes</div>
                      <a href="#">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M10.0003 10.8335C10.4606 10.8335 10.8337 10.4604 10.8337 10.0002C10.8337 9.53993 10.4606 9.16683 10.0003 9.16683C9.54009 9.16683 9.16699 9.53993 9.16699 10.0002C9.16699 10.4604 9.54009 10.8335 10.0003 10.8335Z"
                            stroke="#98A2B3" stroke-width="1.66667"
                            stroke-linecap="round" stroke-linejoin="round" />
                          <path
                            d="M10.0003 5.00016C10.4606 5.00016 10.8337 4.62707 10.8337 4.16683C10.8337 3.70659 10.4606 3.3335 10.0003 3.3335C9.54009 3.3335 9.16699 3.70659 9.16699 4.16683C9.16699 4.62707 9.54009 5.00016 10.0003 5.00016Z"
                            stroke="#98A2B3" stroke-width="1.66667"
                            stroke-linecap="round" stroke-linejoin="round" />
                          <path
                            d="M10.0003 16.6668C10.4606 16.6668 10.8337 16.2937 10.8337 15.8335C10.8337 15.3733 10.4606 15.0002 10.0003 15.0002C9.54009 15.0002 9.16699 15.3733 9.16699 15.8335C9.16699 16.2937 9.54009 16.6668 10.0003 16.6668Z"
                            stroke="#98A2B3" stroke-width="1.66667"
                            stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </a>
                    </div>
                    <div className="g-content-box">
                      <div className="content-box">
                        <div className="font-30 hd-title">3</div>
                        <div className="progrss-sec">
                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M10.0003 15.8332V4.1665M10.0003 4.1665L4.16699 9.99984M10.0003 4.1665L15.8337 9.99984"
                              stroke="#17B26A" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round" />
                          </svg>
                          <p><span>1%</span> last mth</p>
                        </div>
                      </div>
                      <div className="content-box">
                      <HomeLineChart />
                      </div>
                    </div>

                  </div>
                </div>

                <div className="col-md-6 col-12">
                  <div className="home-staff-create graph-content">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M8.12602 14.0002C8.57006 15.7255 10.1362 17.0002 12 17.0002C13.8638 17.0002 15.4299 15.7255 15.874 14.0002M11.0177 2.76424L4.23539 8.03937C3.78202 8.39199 3.55534 8.5683 3.39203 8.7891C3.24737 8.98469 3.1396 9.20503 3.07403 9.4393C3 9.70376 3 9.99094 3 10.5653V17.8002C3 18.9203 3 19.4804 3.21799 19.9082C3.40973 20.2845 3.71569 20.5905 4.09202 20.7822C4.51984 21.0002 5.07989 21.0002 6.2 21.0002H17.8C18.9201 21.0002 19.4802 21.0002 19.908 20.7822C20.2843 20.5905 20.5903 20.2845 20.782 19.9082C21 19.4804 21 18.9203 21 17.8002V10.5653C21 9.99094 21 9.70376 20.926 9.4393C20.8604 9.20503 20.7526 8.98469 20.608 8.7891C20.4447 8.5683 20.218 8.39199 19.7646 8.03937L12.9823 2.76424C12.631 2.49099 12.4553 2.35436 12.2613 2.30184C12.0902 2.2555 11.9098 2.2555 11.7387 2.30184C11.5447 2.35436 11.369 2.49099 11.0177 2.76424Z"
                        stroke="#0A0D14" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                    <div className="create-contnt">
                      <div className="font-16 hd-title">Create new home</div>
                      <div className="hd-subtitle">Then add staff and members</div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="home-staff-create graph-content">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M12 15.5H7.5C6.10444 15.5 5.40665 15.5 4.83886 15.6722C3.56045 16.06 2.56004 17.0605 2.17224 18.3389C2 18.9067 2 19.6044 2 21M19 21V15M16 18H22M14.5 7.5C14.5 9.98528 12.4853 12 10 12C7.51472 12 5.5 9.98528 5.5 7.5C5.5 5.01472 7.51472 3 10 3C12.4853 3 14.5 5.01472 14.5 7.5Z"
                        stroke="#344054" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                    <div className="create-contnt">
                      <div className="font-16 hd-title">Add new staff</div>
                      <div className="hd-subtitle">Before assigning roles and members</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 col-12 mt-4">
                  <div className="data-table-custom">
                    <div className="graph-header">
                      <div className="font-18 hd-title">Homes</div>
                      <a href="#">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M10.0003 10.8335C10.4606 10.8335 10.8337 10.4604 10.8337 10.0002C10.8337 9.53993 10.4606 9.16683 10.0003 9.16683C9.54009 9.16683 9.16699 9.53993 9.16699 10.0002C9.16699 10.4604 9.54009 10.8335 10.0003 10.8335Z"
                            stroke="#98A2B3" stroke-width="1.66667"
                            stroke-linecap="round" stroke-linejoin="round" />
                          <path
                            d="M10.0003 5.00016C10.4606 5.00016 10.8337 4.62707 10.8337 4.16683C10.8337 3.70659 10.4606 3.3335 10.0003 3.3335C9.54009 3.3335 9.16699 3.70659 9.16699 4.16683C9.16699 4.62707 9.54009 5.00016 10.0003 5.00016Z"
                            stroke="#98A2B3" stroke-width="1.66667"
                            stroke-linecap="round" stroke-linejoin="round" />
                          <path
                            d="M10.0003 16.6668C10.4606 16.6668 10.8337 16.2937 10.8337 15.8335C10.8337 15.3733 10.4606 15.0002 10.0003 15.0002C9.54009 15.0002 9.16699 15.3733 9.16699 15.8335C9.16699 16.2937 9.54009 16.6668 10.0003 16.6668Z"
                            stroke="#98A2B3" stroke-width="1.66667"
                            stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </a>
                    </div>
                  </div>
                  <div className="right-main-header search-sec">
                    <div className="left-side-content">
                      <button type="button" className="btn btn-light">
                        20 + Members
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path d="M15 5L5 15M5 5L15 15" stroke="#344054"
                            stroke-width="1.66667" stroke-linecap="round"
                            stroke-linejoin="round" />
                        </svg>
                      </button>
                      <button type="button" className="btn btn-light">
                        Arizona
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path d="M15 5L5 15M5 5L15 15" stroke="#344054"
                            stroke-width="1.66667" stroke-linecap="round"
                            stroke-linejoin="round" />
                        </svg>
                      </button>
                      <button type="button" className="btn btn-light">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path d="M5 10H15M2.5 5H17.5M7.5 15H12.5" stroke="#344054"
                            stroke-width="1.66667" stroke-linecap="round"
                            stroke-linejoin="round" />
                        </svg>
                        20 + Members
                      </button>
                    </div>
                    <div className="left-side-content searchbar">
                      <div className="form-group"><input type="text" name="" id=""
                        className="form-control" placeholder="Search" />
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M16.5 16.5L13.5834 13.5833M15.6667 8.58333C15.6667 12.4954 12.4954 15.6667 8.58333 15.6667C4.67132 15.6667 1.5 12.4954 1.5 8.58333C1.5 4.67132 4.67132 1.5 8.58333 1.5C12.4954 1.5 15.6667 4.67132 15.6667 8.58333Z"
                            stroke="#667085" stroke-width="1.66667"
                            stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="table-sec">
                    <div className="table-responsive">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th>
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox"
                                  value="" id="flexCheckDefault" />
                                <label className="form-check-label"
                                  for="flexCheckDefault">Homes</label>
                              </div>
                            </th>
                            <th scope="col">Status</th>
                            <th scope="col">Current capacity</th>
                            <th scope="col">Location</th>
                            <th scope="col">Staff</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <div className="table-profile">
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox"
                                    value="" id="flexCheckIndeterminate" />
                                </div>
                                <div className="profile-sec">
                                  <span className="name yellow">HJ</span>
                                  <div className="profile-info">
                                    <div className="font-14 hd-title">Hawa Group
                                      Home</div>
                                    <div className="font-14 hd-subtitle">+254
                                      797923344</div>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="badges"><span className="pending"></span>
                                Pending</div>
                            </td>
                            <td>23 members</td>
                            <td>
                              <div className="font-14 hd-title">Arizona</div>
                              <div className="hd-subtitle">Tucson</div>
                            </td>
                            <td>43</td>
                          </tr>
                          <tr>
                            <td>
                              <div className="table-profile">
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox"
                                    value="" id="flexCheckIndeterminate" />
                                </div>
                                <div className="profile-sec">
                                  <span className="name green">HJ</span>
                                  <div className="profile-info">
                                    <div className="font-14 hd-title">Hawa Group
                                      Home</div>
                                    <div className="font-14 hd-subtitle">+254
                                      797923344</div>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="badges"><span className="actives"></span>
                                Pending</div>
                            </td>
                            <td>23 members</td>
                            <td>
                              <div className="font-14 hd-title">Arizona</div>
                              <div className="hd-subtitle">Tucson</div>
                            </td>
                            <td>43</td>
                          </tr>
                          <tr>
                            <td>
                              <div className="table-profile">
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox"
                                    value="" id="flexCheckIndeterminate" />
                                </div>
                                <div className="profile-sec">
                                  <span className="name blue">HJ</span>
                                  <div className="profile-info">
                                    <div className="font-14 hd-title">Hawa Group
                                      Home</div>
                                    <div className="font-14 hd-subtitle">+254
                                      797923344</div>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="badges"><span className="closed"></span> Pending
                              </div>
                            </td>
                            <td>23 members</td>
                            <td>
                              <div className="font-14 hd-title">Arizona</div>
                              <div className="hd-subtitle">Tucson</div>
                            </td>
                            <td>43</td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div className="paging">
                                <button type="button"
                                  className="btn btn-light">Previous</button>
                                <div className="page-num">Page 1 of 10</div>
                                <button type="button"
                                  className="btn btn-light">Next</button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </main>
    </div>
  )
}

export default Dashboard