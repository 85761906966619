import React, { useEffect, useState } from "react";
import { useForm, useFieldArray } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Add_Medication } from '../../../redux/services/AuthMar'
import { PlusSvg, ActiveSvg } from '../../../Images/MarSvg'
import AddNewMedication from './AddNewMedication'

const AddMar = ({ onModalToggle, editMemberDetails = {} }) => {
	const dispatch = useDispatch();
	const AllUserListMar = useSelector(state => state?.AuthMar?.AllUserListMar);
	const AllMemberListMar = useSelector(state => state?.AuthMar?.AllMemberListMar);
	const MasterMedicationList = useSelector(state => state?.AuthMar?.MasterMedicationList);
	const MasterUnitsList = useSelector(state => state?.AuthMar?.MasterUnitsList);
	const MasterDoesForm = useSelector(state => state?.AuthMar?.MasterDoesForm);
	const MasterCodingSystem = useSelector(state => state?.AuthMar?.MasterCodingSystem);
	const MasterRoute = useSelector(state => state?.AuthMar?.MasterRoute);
	const MasterTypeList = useSelector(state => state?.AuthMar?.MasterTypeList);

	const [frequencyValue, setFrequencyValue] = useState('1')
	const [approvelName, setApprovelNamel] = useState('');
	// const [addMedication, setAddMedication] = useState(false)


	const { control, handleSubmit, register, setError, setValue, formState: { errors } } = useForm({
		defaultValues: { frequencyTimes: [{ administer_time: '' }], }
	});
	const { fields, append, remove } = useFieldArray({ control, name: 'frequencyTimes' });

	useEffect(() => {
		if (Object.keys(editMemberDetails).length > 0) {
			const findItemByIdName = (list, id) => list?.find(item => item.id === id) || '';
			const userName = findItemByIdName(AllUserListMar, editMemberDetails?.approval_request_to)
			setApprovelNamel(`${userName?.first_name} ${userName?.last_name}`)
			setValue('approval_request_to', editMemberDetails?.approval_request_to)
			setValue('member_id', editMemberDetails?.member?.id)
			setValue('medication_name', editMemberDetails?.medication_name)
			setValue('dose_form', editMemberDetails?.dose_form)
			setValue('medication_code', editMemberDetails?.medication_code)
			setValue('coding_system', editMemberDetails?.coding_system)
			setValue('strength', editMemberDetails?.strength)
			setValue('strength_unit', editMemberDetails?.strength_unit)
			setValue('type', editMemberDetails?.type)
			setValue('adminis_start_date', editMemberDetails?.adminis_start_date)
			setValue('adminis_end_date', editMemberDetails?.adminis_end_date)
			setValue('route', editMemberDetails?.route)
			setValue('frequency', editMemberDetails?.frequency)
			setFrequencyValue(editMemberDetails?.frequency)
			setValue('give_amount_quantity', editMemberDetails?.give_amount_quantity)
			setValue('measurement_unit', editMemberDetails?.measurement_unit)

			const filterdData = editMemberDetails?.membersMarDetailTimeDay.filter((item) => item?.administer_date === editMemberDetails?.membersMarDetailTimeDay[0].administer_date)
			const lengthDifference = filterdData && filterdData.length - fields.length;
			if (lengthDifference > 0) {
				for (let i = 0; i < lengthDifference; i++) {
					append({});
				}
			}
			filterdData && filterdData?.forEach((item, index) => {
				setValue(`frequencyTimes.${index}.administer_time`, item.administer_time || '');
			});
		}

	}, [editMemberDetails])

	const onSubmit = async (data) => {
		const { adminis_start_date, adminis_end_date } = data;
		// Validate required fields
		if (!adminis_start_date) {
			setError("adminis_start_date", {
				type: "manual",
				message: "Begin Date is required!"
			});
		}
		if (!adminis_end_date) {
			setError("adminis_end_date", {
				type: "manual",
				message: "End Date is required!"
			});
		}
		// Validate date logic
		if (adminis_start_date && adminis_end_date) {
			if (new Date(adminis_end_date) < new Date(adminis_start_date)) {
				setError("adminis_end_date", {
					type: "manual",
					message: "End Date cannot be before Start Date!"
				});
			} else {
				data.members_mar_detail_id = editMemberDetails?.id || ''
				try {
					const response = await dispatch(Add_Medication(data));
					if (response?.payload?.status) {
						onModalToggle(false)
					}
				} catch (error) {
					console.error("Error during add MAR:", error);
				}
			}
		}
	}

	const handleFrequency = (e) => {
		const value = parseInt(e.target.value);
		setValue('frequency', value)
		setFrequencyValue(value)
		// Adjust the fields array length based on entered value
		if (fields.length < value) {
			for (let i = fields.length; i < value; i++) {
				append({ administer_time: '' });
			}
		} else {
			for (let i = fields.length; i > value; i--) {
				remove(i - 1);
			}
		}
	}
	// const handleAddMedication = () => {
	// 	setAddMedication(!addMedication)
	// }

	return (
		<>
			<div class="modal fade new_medi_modal show">
				<div class="modal-dialog modal-lg permision-popup">
					<div class="modal-content ">
						<div class="modal-header">
							<h5 class="modal-title" id="exampleModalLabel"><PlusSvg /></h5>
							<button type="button" class="btn-close" onClick={() => onModalToggle(false)}></button>
						</div>
						<div class="modal-body medication_modal">
							<form class="row" onSubmit={handleSubmit(onSubmit)}>
								<div class="col-7">
									<div class="member_vital_basic_title">
										<h4>{Object.keys(editMemberDetails).length === 0 ? 'Add medication' : 'Update medication'}</h4>
									</div>
									<div class="vital_stepone_para">
										<p>Please fill all details with required fields (*)</p>
									</div>
								</div>
								{Object.keys(editMemberDetails).length > 0 &&
									<div class="col-5">
										<div className="approve_inner">
											<div className="approve_head">Approval status</div>
											<div className="d-flex align-items-center">
												<div className="approve_head">{approvelName}</div>
												<div className={`approv_dot ${editMemberDetails?.approval_status === 'pending' ? 'approve_pend' : 'approve_approv'}`}>{editMemberDetails?.approval_status}</div>
											</div>
										</div>
									</div>
								}
								<hr />
								<div className="col-12">
									<label htmlFor="" className="form-label">Send approval request to*</label>
									<select className="form-select" aria-label="Default select example" name='approval_request_to' {...register('approval_request_to', { required: 'true' })}>
										<option value="">-Please Select -</option>
										{AllUserListMar && AllUserListMar.length > 0 && AllUserListMar.map((item) => <option value={item?.id}>{item?.first_name} {item?.last_name}</option>)}
									</select>
									{errors.approval_request_to && errors.approval_request_to.type === "required" && <span className="error">Send approval request is required</span>}
								</div>

								<div class="col-12">
									<div class="medicat_popup_head">
										<ActiveSvg />
										Member Information
									</div>
								</div>
								<div className="col-12 mb-3">
									<label htmlFor="" className="form-label">Member*</label>
									<select className="form-select" aria-label="Default select example" name='member_id' {...register('member_id', { required: 'true' })} disabled={Object.keys(editMemberDetails).length !== 0}>
										<option value="">-Please Select -</option>
										{AllMemberListMar && AllMemberListMar.map((item) => <option value={item?.id}>{item?.first_name} {item?.last_name}</option>)}
									</select>
									{errors.member_id && errors.member_id.type === "required" && <span className="error">Member is required</span>}
								</div>

								<div class="col-12">
									<div class="medicat_popup_head">
										<ActiveSvg />
										Medication Details
									</div>
									{/* <div class="add_medication_button">
										<button type="button" onClick={() => handleAddMedication()}>+Add new</button>
									</div> */}
								</div>
								<div className="col-md-6 mb-3">
									<label htmlFor="" className="form-label">Medication*</label>
									<select className="form-select" aria-label="Default select example" name='medication_name' {...register('medication_name', { required: 'true' })} disabled={Object.keys(editMemberDetails).length !== 0}>
										<option value="">-Please Select -</option>
										{MasterMedicationList && MasterMedicationList.map((item) => <option value={item?.id}>{item?.name}</option>)}
									</select>
									{errors.medication_name && errors.medication_name.type === "required" && <span className="error">Medication is required</span>}
								</div>
								<div className="col-md-6 mb-3">
									<label htmlFor="" className="form-label">Dose Form*</label>
									<select className="form-select" aria-label="Default select example" name='dose_form' {...register('dose_form', { required: 'true' })}>
										<option value="">-Please Select -</option>
										{MasterDoesForm && MasterDoesForm.map((item) => <option value={item?.id}>{item?.name}</option>)}
									</select>
									{errors.dose_form && errors.dose_form.type === "required" && <span className="error">Dose Form is required</span>}
								</div>
								<div className="col-md-6 mb-3">
									<label htmlFor="" className="form-label">Code*</label>
									<input type="input" className="form-control" id="" placeholder="Code" {...register('medication_code', { required: "true" })} disabled={Object.keys(editMemberDetails).length !== 0} />
									{errors.medication_code && errors.medication_code.type === "required" && <span className="error">Code is required!</span>}
								</div>
								<div className="col-md-6 mb-3">
									<label htmlFor="" className="form-label">Coding System*</label>
									<select className="form-select" aria-label="Default select example" name='coding_system' {...register('coding_system', { required: 'true' })}>
										<option value="">-Please Select -</option>
										{MasterCodingSystem && MasterCodingSystem.map((item) => <option value={item?.id}>{item?.name}</option>)}
									</select>
									{errors.coding_system && errors.coding_system.type === "required" && <span className="error">Coding System is required</span>}
								</div>
								<div className="col-md-6 mb-3">
									<label htmlFor="" className="form-label">Strength*</label>
									<input type="input" className="form-control" id="" placeholder="Strength" {...register('strength', { required: "true" })} disabled={Object.keys(editMemberDetails).length !== 0} />
									{errors.strength && errors.strength.type === "required" && <span className="error">Strength is required!</span>}
								</div>
								<div className="col-md-6 mb-3">
									<label htmlFor="" className="form-label">Strength Unit*</label>
									<select className="form-select" aria-label="Default select example" name='strength_unit' {...register('strength_unit', { required: 'true' })}>
										<option value="">-Please Select -</option>
										{MasterUnitsList && MasterUnitsList.map((item) => {
											let name = item?.name
											if (item?.type === 'Strength') {
												return (
													<option value={item?.id}>{name.toLowerCase()}</option>
												)
											}
										})}
									</select>
									{errors.strength_unit && errors.strength_unit.type === "required" && <span className="error">Strength Unit is required</span>}
								</div>
								<div className="col-md-6 mb-3">
									<label htmlFor="" className="form-label">Type*</label>
									<select className="form-select" aria-label="Default select example" name='type' {...register('type', { required: 'true' })}>
										<option value="">-Please Select -</option>
										{MasterTypeList && MasterTypeList.map((item) => <option value={item?.id}>{item?.name}</option>)}
									</select>
									{errors.type && errors.type.type === "required" && <span className="error">Type is required</span>}
								</div>

								<div class="col-12">
									<div class="medicat_popup_head">
										<ActiveSvg />
										Administration Details
									</div>
								</div>
								<div className="col-md-6 mb-3">
									<label for="" className="form-label">Begin Date*</label>
									<input
										type="date"
										className="form-control"
										{...register("adminis_start_date", { required: "Begin Date is required!" })}
										onKeyDown={(e) => e.preventDefault()}
										disabled={Object.keys(editMemberDetails).length !== 0}
									/>
									{errors.adminis_start_date && (
										<span className="error">{errors.adminis_start_date.message}</span>
									)}
								</div>
								<div className="col-md-6 mb-3">
									<label for="" className="form-label">End Date*</label>
									<input
										type="date"
										className="form-control"
										{...register("adminis_end_date", { required: "End Date is required!" })}
										onKeyDown={(e) => e.preventDefault()}
									/>
									{errors.adminis_end_date && (
										<span className="error">{errors.adminis_end_date.message}</span>
									)}
								</div>
								<div className="col-md-6 mb-3">
									<label htmlFor="" className="form-label">Route*</label>
									<select className="form-select" aria-label="Default select example" name='route' {...register('route', { required: 'true' })}>
										<option value="">-Please Select -</option>
										{MasterRoute && MasterRoute.map((item) => <option value={item?.id}>{item?.name}</option>)}
									</select>
									{errors.route && errors.route.type === "required" && <span className="error">Route is required</span>}
								</div>
								<div className="col-md-6 mb-3">
									<label htmlFor="" className="form-label">Frequency*</label>
									<select className="form-select" aria-label="Default select example" name='frequency' value={frequencyValue} onChange={(e) => handleFrequency(e)}>
										<option value="1">1 x daily</option>
										<option value="2">2 x daily</option>
										<option value="3">3 x daily</option>
										<option value="4">4 x daily</option>
									</select>
									{errors.frequency && errors.frequency.type === "required" && <span className="error">Frequency is required</span>}
								</div>
								<div className="row">
									{fields.map((item, index) => {
										return (
											<>
												<div className="col-md-4 mb-3">
													<label htmlFor="" className="form-label">Time {Number(index) + 1}*</label>

													<input type="time" className="form-control" id="" placeholder="Exp. 3:00pm" {...register(`frequencyTimes.${index}.administer_time`, { required: "Time is required!", })} onKeyDown={(e) => e.preventDefault()} />
													{errors.frequencyTimes?.[index]?.administer_time && (
														<span className="error">{errors.frequencyTimes[index].administer_time.message}</span>
													)}
												</div>
											</>
										)
									}
									)}
								</div>
								<div className="col-md-6 mb-3">
									<label htmlFor="" className="form-label">Give Amount / Quantity*</label>
									<input type="input" className="form-control" id="" placeholder="Give Amount / Quantity" name="give_amount_quantity" {...register('give_amount_quantity', { required: "true" })} />
									{errors.give_amount_quantity && errors.give_amount_quantity.type === "required" && <span className="error">Ammount is required!</span>}
								</div>
								<div className="col-md-6 mb-3">
									<label htmlFor="" className="form-label">Measurement Unit*</label>
									<select className="form-select" aria-label="Default select example" name='measurement_unit' {...register('measurement_unit', { required: 'true' })}>
										<option value="">-Please Select -</option>
										{MasterUnitsList && MasterUnitsList.map((item) => {
											if (item?.type === 'Measurement') {
												return (
													<option value={item?.id}>{item?.name}</option>
												)
											}
										})}
									</select>
									{errors.measurement_unit && errors.measurement_unit.type === "required" && <span className="error">Measurement Unit is required</span>}
								</div>
								<div class="up_vitalm_btn d-flex">
									<button type="button" class="w-50" onClick={() => onModalToggle(false)}>Cancel</button>
									<button type="submit" class="w-50">{Object.keys(editMemberDetails).length === 0 ? 'Save' : 'Save changes'}</button>
								</div>
							</form>
						</div>
					</div>
				</div>
				{/* {addMedication && <AddNewMedication onModalToggle={handleAddMedication} />} */}
			</div>
		</>

	)
}
export default AddMar