import React, { useState, useEffect } from "react";
import AddComment from './AddComment';
import { formatAMPM, monthDayFormateOpenMAR, calculateTimeDifference } from '../../../components/Common/Common';

// Color mapping for different status types
const colors = {
    'overdue': '#B42318',
    'pending': '#528BFF',
    'administered': '#2342BF',
    'future': '#D0D5DD',
    'gray': '#98A2B3'
};

// Month names for display purposes
const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const Calendar = ({ calanderData }) => {
    const [allDays, setAllDays] = useState([]);
    const [timeArray, setTimeArray] = useState([]);
    const [allData, setAllData] = useState([]);
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [addComment, setAddComment] = useState(false);
    const [checkBoxId, setCheckBoxId] = useState('');
    const [calanderId, setCalanderId] = useState('');

    // Set the initial month based on the calendar data's start date
    useEffect(() => {
        if (calanderData) {
            const startDate = new Date(calanderData.adminis_start_date);
            setCurrentMonth(startDate);
        }
    }, [calanderData]);

    // Function to get all days in the current month
    const getDaysInMonth = (year, month) => {
        const daysInMonth = new Date(year, month + 1, 0).getDate();
        return Array.from({ length: daysInMonth }, (_, i) => new Date(year, month, i + 1));
    };

    // Process calendar data whenever it or the current month changes
    useEffect(() => {
        if (calanderData && calanderData.membersMarDetailTimeDay) {
            const month = currentMonth.getMonth();
            const year = currentMonth.getFullYear();
            const days = getDaysInMonth(year, month).map(date =>
                date.toLocaleDateString([], { day: "numeric", weekday: "short" })
            );
            setAllDays(days);

            const data = [];
            const allDataArray = calanderData.membersMarDetailTimeDay.map(item => {
                const formattedTime = formatAMPM(item?.administer_time);
                data.push(formattedTime);
                return {
                    ...item,
                    time: formattedTime,
                    date: monthDayFormateOpenMAR(item?.administer_date),
                    status: item?.administer_status,
                    color: item?.administer_status || 'null',
                    id: item?.id,
                    calanderId: calanderData?.id,
                    approval_status: calanderData?.approval_status
                };
            });

            // Set unique times and all event data
            const uniqueTimes = Array.from(new Set(data));
            setTimeArray(uniqueTimes);
            setAllData(allDataArray);
        }
    }, [calanderData, currentMonth]);

    // Function to get the color for a specific day based on its events
    const getColorForDay = (time, day) => {
        const event = allData.find(event => event.time === time && event.date === day);
        if (event) {
            console.log(event, 'eventeventeventeventevent');
            return colors[event.color]
        } else {
            return colors['gray']
        }

    };

    // Tooltip component to show event details
    const Tooltip = ({ children, time, date, status, userName }) => {
        const getStatusKey = (color) => Object.keys(colors).find(key => colors[key] === color);
        const statusKey = getStatusKey(status);

        return (
            <div className="tooltip-container">
                {children}
                <div className="tooltip">
                    <p>Time: {time}</p>
                    <p>Date: {date}</p>
                    <p>Status: {statusKey === 'gray' ? 'No record' : statusKey}</p>
                    {userName !== '' &&
                        <p>Administred Meds: {userName}</p>
                    }
                </div>
            </div>
        );
    };

    // Check if the administering hour is disabled based on current time
    const getDisableHours = (data) => {
        let hours = calculateTimeDifference(data);
        if (hours.includes('day') || hours.includes('days')) {
            return true;
        }
        if (hours.includes('minute') || hours.includes('minutes')) {
            return false;
        }
        if (hours.includes('hour') || hours.includes('hours')) {
            let splitHours = hours.split(' ');
            return Number(splitHours[0]) > 2;
        }
    };

    // Handle click events on the calendar to toggle comments
    const handleCalanderClick = (time, day) => {
        const event = allData.find(event => event.time === time && event.date === day);
        const clickedEvent = calanderData?.membersMarDetailTimeDay?.find(item => item?.id === event?.id);

        if (clickedEvent) {
            const disableHour = getDisableHours(`${clickedEvent?.administer_date} ${clickedEvent?.administer_time}`);
            const disabled = event?.approval_status === 'pending' || event?.approval_status === 'administered' ? true : disableHour;
            if (!disabled) {
                setCheckBoxId(event?.id);
                setCalanderId(event?.calanderId);
                setAddComment(true);
            }
        }
    };

    // Change the displayed month in the calendar
    const changeMonth = (increment) => {
        setCurrentMonth(prevMonth => {
            const newDate = new Date(prevMonth);
            newDate.setMonth(prevMonth.getMonth() + increment);
            return newDate;
        });
    };

    // Display current month and year
    const monthDisplay = `${monthNames[currentMonth.getMonth()]} ${currentMonth.getFullYear()}`;

    return (
        <>
            <div className="mar_calander">
                <table className="table table-hover mar_table">
                    <thead>
                        <tr>
                            <th scope="col">Time</th>
                            {allDays.map((item, index) => {
                                const week = item.split(' ');
                                return (
                                    <th scope="col" key={index}>{week[1]} {week[0]}</th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {timeArray.map((time, index) => (
                            <tr key={index}>
                                <td>{time}</td>
                                {allDays.map((day, index) => {
                                    const event = allData.find(event => event.time === time && event.date === day);
                                    let userName = ''
                                    let clickedEvent = {}
                                    if(event){
                                        clickedEvent = calanderData?.membersMarDetailTimeDay?.find(item => item?.id === event?.id);
                                    }
                                    if (clickedEvent && clickedEvent?.administer_status === 'administered') {
                                        userName = clickedEvent?.adminMedsuser?.initial_name
                                    }
                                    let color = getColorForDay(time, day)
                                    return (
                                        <td
                                            key={index}
                                            style={{ background: color }}
                                            onClick={() => handleCalanderClick(time, day)}
                                        >
                                            <Tooltip time={time} date={day} status={color} userName={userName} />
                                        </td>
                                    )
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="month-navigation">
                <button type="button" className="btn btn-light" onClick={() => changeMonth(-1)}>Previous</button>
                <span>{monthDisplay}</span>
                <button type="button" className="btn btn-light" onClick={() => changeMonth(1)}>Next</button>
            </div>
            {addComment && <AddComment handleCommentToggle={setAddComment} checkBoxId={checkBoxId} parentId={calanderId} />}
        </>
    );
};

export default Calendar;
