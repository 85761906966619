import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const RedLineChart = () => {
  const chartContainer = useRef(null);

  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      const redlineChartctx = chartContainer.current.getContext('2d');

      const gradientFill = redlineChartctx.createLinearGradient(0, 160, 0, 0);
      gradientFill.addColorStop(0, "rgba(240, 68, 56, 0)");
      gradientFill.addColorStop(1, "rgba(240, 68, 56, 1)");

      const data = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June'],
        datasets: [{
          label: 'My Dataset',
          data: [6, 8, 3, 12, 16, 5],
          fill: true,
          backgroundColor: gradientFill,
          borderColor: "rgba(240, 68, 56, 1)",
          pointBackgroundColor: "rgba(255, 255, 255, 1)",
          pointBorderColor: "rgba(240, 68, 56, 1)",
          pointBorderWidth: 2,
          pointRadius: 5
        }]
      };

      new Chart(redlineChartctx, {
        type: 'line',
        data: data,
        options: {
          scales: {
            x: {
              grid: {
                display: false
              },
              ticks: {
                display: false
              }
            },
            y: {
              grid: {
                display: false
              },
              ticks: {
                display: false
              }
            }
          }
        }
      });
    }
  }, []);

  return <canvas ref={chartContainer} id="redlineChart" width="400" height="400"></canvas>;
};

export default RedLineChart;
