import React from 'react'

const Documents = () => {
    return (
        <div className="tab-pane active show" id="billing" role="tabpanel"
            aria-labelledby="billing-tab">
            <div className="row">
                <div className="col-md-12 col-12">
                    <div className="table-btn-sec">
                        <div className="form-group searchbar">
                            <input type="text" name=""
                                id="" className="form-control" placeholder="Search" />
                            <svg width="18" height="18" viewBox="0 0 18 18"
                                fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M16.5 16.5L13.5834 13.5833M15.6667 8.58333C15.6667 12.4954 12.4954 15.6667 8.58333 15.6667C4.67132 15.6667 1.5 12.4954 1.5 8.58333C1.5 4.67132 4.67132 1.5 8.58333 1.5C12.4954 1.5 15.6667 4.67132 15.6667 8.58333Z"
                                    stroke="#667085" stroke-width="1.66667"
                                    stroke-linecap="round" stroke-linejoin="round">
                                </path>
                            </svg>
                        </div>
                        <button type="button" className="btn btn-light">
                            <svg width="20" height="20" viewBox="0 0 20 20"
                                fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5 10H15M2.5 5H17.5M7.5 15H12.5"
                                    stroke="#344054" stroke-width="1.66667"
                                    stroke-linecap="round" stroke-linejoin="round">
                                </path>
                            </svg>
                            More Filters
                        </button>
                    </div>
                    <div className="table-sec">
                        <div className="table-responsive">
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th>
                                            <div className="form-check">
                                                <input className="form-check-input"
                                                    type="checkbox" value=""
                                                    id="flexCheckDefault" />
                                                <label className="form-check-label"
                                                    for="flexCheckDefault">Name</label>
                                            </div>
                                        </th>
                                        <th scope="col">Type</th>
                                        <th scope="col">Category</th>
                                        <th scope="col">Uploaded by</th>
                                        <th scope="col">Uploaded date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className="table-profile">
                                                <div className="form-check">
                                                    <input className="form-check-input"
                                                        type="checkbox" value=""
                                                        id="flexCheckIndeterminate" />
                                                </div>
                                                <div className="profile-sec">
                                                    <div className="profile-info">
                                                        <div
                                                            className="font-14 hd-title">
                                                            Full File review 2020
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>PDF</td>
                                        <td>Communications</td>
                                        <td>Paul Pierce</td>
                                        <td>12/07/2024</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="table-profile">
                                                <div className="form-check">
                                                    <input className="form-check-input"
                                                        type="checkbox" value=""
                                                        id="flexCheckIndeterminate" />
                                                </div>
                                                <div className="profile-sec">
                                                    <div className="profile-info">
                                                        <div
                                                            className="font-14 hd-title">
                                                            Vital Information &
                                                            Individualized Needs
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>PDF</td>
                                        <td>Medication</td>
                                        <td>Kevin Garnett</td>
                                        <td>12/07/2024</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="table-profile">
                                                <div className="form-check">
                                                    <input className="form-check-input"
                                                        type="checkbox" value=""
                                                        id="flexCheckIndeterminate" />
                                                </div>
                                                <div className="profile-sec">

                                                    <div className="profile-info">
                                                        <div
                                                            className="font-14 hd-title">
                                                            DDD Person-Centered
                                                            Service Plan</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>PNG</td>
                                        <td>Appointment</td>
                                        <td>Ray Allen</td>
                                        <td>15/07/2024</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="table-profile">
                                                <div className="form-check">
                                                    <input className="form-check-input"
                                                        type="checkbox" value=""
                                                        id="flexCheckIndeterminate" />
                                                </div>
                                                <div className="profile-sec">
                                                    <div className="profile-info">
                                                        <div
                                                            className="font-14 hd-title">
                                                            Full File review 2020
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>PDF</td>
                                        <td>Communications</td>
                                        <td>Paul Pierce</td>
                                        <td>12/07/2024</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="table-profile">
                                                <div className="form-check">
                                                    <input className="form-check-input"
                                                        type="checkbox" value=""
                                                        id="flexCheckIndeterminate" />
                                                </div>
                                                <div className="profile-sec">
                                                    <div className="profile-info">
                                                        <div
                                                            className="font-14 hd-title">
                                                            Full File review 2020
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>PDF</td>
                                        <td>Communications</td>
                                        <td>Paul Pierce</td>
                                        <td>12/07/2024</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="table-profile">
                                                <div className="form-check">
                                                    <input className="form-check-input"
                                                        type="checkbox" value=""
                                                        id="flexCheckIndeterminate" />
                                                </div>
                                                <div className="profile-sec">
                                                    <div className="profile-info">
                                                        <div
                                                            className="font-14 hd-title">
                                                            Full File review 2020
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>PDF</td>
                                        <td>Communications</td>
                                        <td>Paul Pierce</td>
                                        <td>12/07/2024</td>
                                    </tr>
                                 {/* </tr> */}
                                <tr>
                                    <td>
                                        <div className="table-profile">
                                            <div className="form-check">
                                                <input className="form-check-input"
                                                    type="checkbox" value=""
                                                    id="flexCheckIndeterminate" />
                                            </div>
                                            <div className="profile-sec">

                                                <div className="profile-info">
                                                    <div
                                                        className="font-14 hd-title">
                                                        Vital Information &
                                                        Individualized Needs
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>PDF</td>
                                    <td>Medication</td>
                                    <td>Kevin Garnett</td>
                                    <td>12/07/2024</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="table-profile">
                                            <div className="form-check">
                                                <input className="form-check-input"
                                                    type="checkbox" value=""
                                                    id="flexCheckIndeterminate" />
                                            </div>
                                            <div className="profile-sec">

                                                <div className="profile-info">
                                                    <div
                                                        className="font-14 hd-title">
                                                        DDD Person-Centered
                                                        Service Plan</div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>PNG</td>
                                    <td>Appointment</td>
                                    <td>Ray Allen</td>
                                    <td>15/07/2024</td>
                                </tr>
                                <tr>
                                    <td colspan="7">
                                        <div className="paging">
                                            <button type="button"
                                                className="btn btn-light">Previous</button>
                                            <div className="page-num">Page 1 of 10
                                            </div>
                                            <button type="button"
                                                className="btn btn-light">Next</button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}

export default Documents