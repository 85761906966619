import React from 'react'

export const Appointment = () => {
  return (
    <div className="tab-pane fade active show" id="contact" role="tabpanel"
    aria-labelledby="contact-tab">
    <div className="saf-img-upload">
        <button type="button" className="btn btn-light"><svg width="18"
            height="20" viewBox="0 0 18 20" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.5 8.33317H1.5M12.3333 1.6665V4.99984M5.66667 1.6665V4.99984M5.5 18.3332H12.5C13.9001 18.3332 14.6002 18.3332 15.135 18.0607C15.6054 17.821 15.9878 17.4386 16.2275 16.9681C16.5 16.4334 16.5 15.7333 16.5 14.3332V7.33317C16.5 5.93304 16.5 5.23297 16.2275 4.69819C15.9878 4.22779 15.6054 3.84534 15.135 3.60565C14.6002 3.33317 13.9001 3.33317 12.5 3.33317H5.5C4.09987 3.33317 3.3998 3.33317 2.86502 3.60565C2.39462 3.84534 2.01217 4.22779 1.77248 4.69819C1.5 5.23297 1.5 5.93304 1.5 7.33317V14.3332C1.5 15.7333 1.5 16.4334 1.77248 16.9681C2.01217 17.4386 2.39462 17.821 2.86502 18.0607C3.3998 18.3332 4.09987 18.3332 5.5 18.3332Z"
                stroke="#344054" stroke-width="1.66667"
                stroke-linecap="round" stroke-linejoin="round" />
        </svg>
            Jan 6, 2024 – Jan 13, 2024</button>

        <div className="member_day_main">
            <img src="../assets/images/Icon.png" alt="" />
            <div className="member_day_btn">
                <button type="button" className="btn btn-light"><svg width="20"
                    height="20" viewBox="0 0 20 20" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 10H15M2.5 5H17.5M7.5 15H12.5"
                        stroke="#344054" stroke-width="1.66667"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>
                </button>
                <button type="button" className="btn btn-light">Day</button>
                <button type="button" className="btn btn-light badges"><span
                    className="actives"></span> Week</button>
                <button type="button" className="btn btn-light">Month</button>
                <select className="form-select"
                    aria-label="Default select example">
                    <option selected>Board view</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                </select>
            </div>
        </div>
    </div>
    <div className="col-md-12 col-12">
        <div className="table-sec">
            <div className="table-responsive">
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th>
                                <div className="form-check">
                                    <input className="form-check-input"
                                        type="checkbox" value=""
                                        id="flexCheckDefault" />
                                    <label className="form-check-label"
                                        for="flexCheckDefault">Appointment
                                        date</label>
                                </div>
                            </th>
                            <th scope="col">With</th>
                            <th scope="col">Location</th>
                            <th scope="col">Approval Status</th>
                            <th scope="col">Appointment Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div className="form-check">
                                    <input className="form-check-input"
                                        type="checkbox" value=""
                                        id="flexCheck" />
                                    <label className="form-check-label"
                                        for="flexCheck">
                                        <div className="font-14 hd-title">
                                            01/08/2023<span
                                                className="d-block">7:00
                                                AM</span></div>
                                    </label>
                                </div>
                                <div className="table-profile">

                                    <div className="profile-sec">
                                        <div className="profile-info">

                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>Dr. Lewis Manroe<span
                                className="d-block">Orthopedic</span></td>
                            <td>Hospital</td>
                            <td>
                                <div className="badges"><span
                                    className="actives"></span> Approved
                                </div>
                            </td>
                            <td>
                                <div className="badges"><span
                                    className="pend"></span>Pending</div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="form-check">
                                    <input className="form-check-input"
                                        type="checkbox" value=""
                                        id="flexCheck1" />
                                    <label className="form-check-label"
                                        for="flexCheck1">
                                        <div className="font-14 hd-title">
                                            01/08/2023<span
                                                className="d-block">7:00
                                                AM</span></div>
                                    </label>
                                </div>
                                <div className="table-profile">

                                    <div className="profile-sec">
                                        <div className="profile-info">

                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>Dr. Lewis Manroe<span
                                className="d-block">Orthopedic</span></td>
                            <td>Online</td>
                            <td>
                                <div className="badges"><span
                                    className="actives"></span> Approved
                                </div>
                            </td>
                            <td>
                                <div className="badges"><span
                                    className="pend"></span>Scheduled</div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="form-check">
                                    <input className="form-check-input"
                                        type="checkbox" value=""
                                        id="flexCheck2" />
                                    <label className="form-check-label"
                                        for="flexCheck2">
                                        <div className="font-14 hd-title">
                                            01/08/2023<span
                                                className="d-block">7:00
                                                AM</span></div>
                                    </label>
                                </div>
                                <div className="table-profile">

                                    <div className="profile-sec">
                                        <div className="profile-info">

                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>Dr. Lewis Manroe<span
                                className="d-block">Orthopedic</span></td>
                            <td>Arizona State Hospital</td>
                            <td>
                                <div className="badges"><span
                                    className="pend"></span>Pending</div>
                            </td>
                            <td>
                                <div className="badges"><span
                                    className="actives"></span>Not Scheduled
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="form-check">
                                    <input className="form-check-input"
                                        type="checkbox" value=""
                                        id="flexCheck3" />
                                    <label className="form-check-label"
                                        for="flexCheck3">
                                        <div className="font-14 hd-title">
                                            01/08/2023<span
                                                className="d-block">7:00
                                                AM</span></div>
                                    </label>
                                </div>
                                <div className="table-profile">

                                    <div className="profile-sec">
                                        <div className="profile-info">

                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>Dr. Lewis Manroe<span
                                className="d-block">Orthopedic</span></td>
                            <td>Missionary Home Centre</td>
                            <td>
                                <div className="badges"><span
                                    className="pend"></span>Pending</div>
                            </td>
                            <td>
                                <div className="badges"><span
                                    className="actives"></span> Completed
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="form-check">
                                    <input className="form-check-input"
                                        type="checkbox" value=""
                                        id="flexCheck4" />
                                    <label className="form-check-label"
                                        for="flexCheck4">
                                        <div className="font-14 hd-title">
                                            01/08/2023<span
                                                className="d-block">7:00
                                                AM</span></div>
                                    </label>
                                </div>
                                <div className="table-profile">
                                    <div className="profile-sec">
                                        <div className="profile-info">
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>Dr. Lewis Manroe<span
                                className="d-block">Orthopedic</span></td>
                            <td>Aga Khan Hospital</td>
                            <td>
                                <div className="badges"><span
                                    className="actives"></span> Approved
                                </div>
                            </td>
                            <td>
                                <div className="badges"><span
                                    className="pend"></span>Pending</div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="form-check">
                                    <input className="form-check-input"
                                        type="checkbox" value=""
                                        id="flexCheck5" />
                                    <label className="form-check-label"
                                        for="flexCheck5">
                                        <div className="font-14 hd-title">
                                            01/08/2023<span
                                                className="d-block">7:00
                                                AM</span></div>
                                    </label>
                                </div>
                                <div className="table-profile">

                                    <div className="profile-sec">
                                        <div className="profile-info">

                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>Dr. Lewis Manroe<span
                                className="d-block">Orthopedic</span></td>
                            <td>Hospital</td>
                            <td>
                                <div className="badges"><span
                                    className="actives"></span> Approved
                                </div>
                            </td>
                            <td>
                                <div className="badges"><span
                                    className="pend"></span>Pending</div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="7">
                                <div className="paging">
                                    <button type="button"
                                        className="btn btn-light">Previous</button>
                                    <div className="page-num">Page 1 of 10</div>
                                    <button type="button"
                                        className="btn btn-light">Next</button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

</div>
  )
}
