import React, { useState, useEffect } from 'react'
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import {Forms} from './Forms'

const getCustomModalStyles = (isSmallScreen) => ({
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        border: 'none',
        background: '#fff',
        borderRadius: '8px',
        padding: isSmallScreen ? '10px' : '20px',
        zIndex: 99,
        transition: 'all 1s ease-in-out',
        maxWidth: '750px',
        width: isSmallScreen ? '95%' : '90%',
        height: 'auto',
        maxHeight: '90vh',
    }
});

export const AfterAddMember = ({ showPopUp, setShowPopUp,id, handleBegin = {}, type = '' }) => {
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
    useEffect(()=>{
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    },[])

    const navigate=useNavigate();
    const onClose=()=>{
        if(showPopUp?.id){
            navigate(`/auth/edit-member/${showPopUp?.id}`)
            setShowPopUp({modal:false});
        }else{
            setShowPopUp({modal:false});
            navigate('/auth/members');
        }
    }
    const customModalStyles = getCustomModalStyles(isSmallScreen)
    return (
        <Modal
            isOpen={showPopUp?.modal}
            onRequestClose={() => setShowPopUp({ ...showPopUp, modal: false })}
            style={customModalStyles}
            contentLabel="Create Role Modal"
        >
            {/* <div className="modal" > */}
                <div className="modal-dialog modal-lg "> 
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="load_img">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M16 12L12 8M12 8L8 12M12 8V16M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                        stroke="#079455" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>

                            </div>
                            <button type="button" className="btn-close" onClick={()=>onClose()}></button>
                        </div>
                        <Forms member_id={showPopUp?.id}/>
                        {/* <div className="modal-body vital_modal_sub_cont">
                            <div className="vital_modal_sub_title">
                                <h5 className="hd-title">{showPopUp?.message}</h5>
                                <p className="hd-subtitle font-14">To complete the member profile, fill the following forms</p>
                            </div>
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Required forms
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <div className="memb_mult_field_main">
                                                <div className="member_field_left">
                                                    <h4>Vital information <span
                                                        className="mem_field_bages comp_badge">Completed</span></h4>
                                                </div>
                                                <div className="member_field_right">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z"
                                                            fill="#D3F8DF" />
                                                        <path
                                                            d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z"
                                                            stroke="#099250" stroke-width="2" />
                                                        <path d="M17.3337 8L10.0003 15.3333L6.66699 12" stroke="#099250"
                                                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="memb_mult_field_main">
                                                <div className="member_field_left">
                                                    <h4>DDD PCSP <span className="mem_field_bages">Not submitted</span></h4>
                                                </div>
                                                <div className="member_field_right">
                                                    <button type="button" onClick={()=>handleBegin()}>Begin</button>
                                                </div>
                                            </div>
                                            <div className="memb_mult_field_main">
                                                <div className="member_field_left">
                                                    <h4>Individualized health care &amp; safety needs <span
                                                        className="mem_field_bages">Not submitted</span></h4>
                                                </div>
                                                <div className="member_field_right">
                                                    <h4>Complete DDD PCSP</h4>
                                                </div>
                                            </div>
                                            <div className="memb_mult_field_main">
                                                <div className="member_field_left">
                                                    <h4>Medical history <span className="mem_field_bages">Not submitted</span></h4>
                                                </div>
                                                <div className="member_field_right">
                                                    <h4>Complete DDD PCSP</h4>
                                                </div>
                                            </div>
                                            <div className="memb_mult_field_main">
                                                <div className="member_field_left">
                                                    <h4>General consent and authorization <span className="mem_field_bages">Not
                                                        submitted</span></h4>
                                                </div>
                                                <div className="member_field_right">
                                                    <h4>Complete DDD PCSP</h4>
                                                </div>
                                            </div>
                                            <div className="memb_mult_field_main">
                                                <div className="member_field_left">
                                                    <h4>General consent and authorization <span className="mem_field_bages">Not
                                                        submitted</span></h4>
                                                </div>
                                                <div className="member_field_right">
                                                    <h4>Complete DDD PCSP</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div> */}
                    </div>
                {/* </div> */}
            </div>
        </Modal>
    )
}
